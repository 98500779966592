import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography, withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setInfo } from '../../../../redux/actions/book-appointment';
import { useCreditCard, useStoredCards } from '../../../../utils/hooks/creditCard';
import { selectCardStyles } from '../styles';
import { useSelector } from 'react-redux';
import StoredCreditCard from './StoredCreditCard';
import CreditCardApi from '../../../../api/creditCardApi';
import SquareCreditCardForm from '../../../../collums-components/components/common/cardModal/SquareCreditCardForm';

function CreditCard({ classes, handleNext, setIsLoading, isLoading }) {
    const dispatch = useDispatch();
    const storedCards = useStoredCards();

    const [selected, setSelected] = useState();
    const [isValidated, setisValidated] = useState(true);
    const [locationId, setLocationId] = useState('');
    const currentCustomer = useSelector(state => state.auth.user);

    const form = useCreditCard();

    const handleSaveCardAndNext = (card, setIsSubmitting) => {
        setIsLoading(true);
        const info = { ...form.values, card, selectedCard: selected };
        dispatch(setInfo(info));
        handleNext(info, setIsSubmitting);
    };

    useEffect(() => {
        if (storedCards) {
            setSelected(storedCards.find(card => card.isDefaultCard) || storedCards[0] || 'NEW_CARD');
        } else {
            setSelected('NEW_CARD');
        }
    }, [storedCards]);

    useEffect(() => {
        async function validateSeller() {
            const res = await CreditCardApi.checkIsConnected();
            setisValidated(res.isConnected);
        }

        validateSeller();
    }, [isValidated]);

    useEffect(() => {
        if (isValidated) {
            setIsLoading(true);
            CreditCardApi.getSeller().then(res => {
                setLocationId(res.squareLocationId);
                setIsLoading(false);
            });
        }
    }, [isValidated, setIsLoading]);

    return (
        <Box display="flex" flexDirection="column" justifyContent="space-between" width={1} height={1} p={2}>
            {isValidated ? (
                <div className={classes.cardBorder}>
                    <div className={classes.cardHeader}>
                        <Typography className={classes.cardTitle}>
                            {selected === 'NEW_CARD'
                                ? 'Please enter your card details to secure your booking.'
                                : 'Card Details'}
                        </Typography>
                    </div>
                    {storedCards?.length !== 0 && (
                        <Box className={classes.cardRoot}>
                            <FormControl variant={'outlined'} className={classes.cardSelector}>
                                <InputLabel>Credit Card</InputLabel>
                                <Select
                                    value={selected}
                                    classes={{ root: classes.cardSelectorRoot }}
                                    onChange={event => setSelected(event.target.value)}
                                    label="Credit Card"
                                >
                                    {(storedCards || []).map(card => (
                                        <MenuItem key={card.id} value={card}>
                                            **** {card.last_4} - {card.card_brand}
                                        </MenuItem>
                                    ))}
                                    <MenuItem value={'NEW_CARD'}>Add new card</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    )}
                    <div className={`card-info ${classes.cardInfoRoot}`}>
                        {selected === 'NEW_CARD' ? (
                            <SquareCreditCardForm
                                currentCustomer={currentCustomer}
                                locationId={locationId}
                                sendForm={handleSaveCardAndNext}
                                closeModal={() => {}}
                                isLoading={isLoading}
                                setLoading={setIsLoading}
                            />
                        ) : (
                            <StoredCreditCard card={selected} />
                        )}
                    </div>
                    {selected !== 'NEW_CARD' && (
                        <>
                            <div className={classes.bookAppointmentButton}>
                                {!isLoading && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            setIsLoading(true);
                                            const info = {
                                                ...form.values,
                                                card: selected.id,
                                                selectedCard: selected.id
                                            };
                                            dispatch(setInfo(info));
                                            handleNext(info, setIsLoading);
                                        }}
                                    >
                                        Book Appointment
                                    </Button>
                                )}
                            </div>
                        </>
                    )}
                </div>
            ) : (
                <Typography className={classes.cardTitle}>
                    There was an issue connecting to our Credit card handler.
                </Typography>
            )}
        </Box>
    );
}

export default withStyles(selectCardStyles)(CreditCard);
