import { MOBILE } from '../../../constants/breakpoints';

export const time = theme => ({
    timeChoose: {
        width: '100%',
        border: `1px solid ${theme.palette.gray.light}`,
        borderRadius: '10px',
        padding: '1rem 1rem 2.5rem',
        [theme.breakpoints.down(MOBILE)]: {
            width: '100%',
            padding: '0rem 0.4rem 0rem 0rem',
            border: 'none',
            '& .MuiButton-containedPrimary': {
                fontSize: '0.9rem'
            }
        }
    },
    hourOption: {
        width: 'fit-content',
        border: `1px solid ${theme.palette.gray.light}`,
        padding: '0.3rem 0.5rem',
        borderRadius: '5px',
        margin: '0.5rem 0.2rem',
        cursor: 'pointer',
        '&:hover': {
            borderColor: '#2b77e4'
        }
    },
    selectedOption: {
        color: 'white',
        backgroundColor: '#2b77e4',
        borderColor: '#2b77e4'
    },
    contentOptions: {
        height: 'fit-content',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    text: {
        fontWeight: '400',
        display: 'flex',
        placeContent: 'center'
    },
    maxHeight: {
        margin: '2rem 0',
        [theme.breakpoints.up(MOBILE)]: {
            height: '11rem'
        }
    },
    btnBook: {
        color: 'white',
        backgroundColor: '#2b77e4'
    },
    disabledOption: {
        backgroundColor: '#e0e0e0',
        cursor: 'default',
        '&:hover': {
            borderColor: '#AAA9A8'
        }
    },
    actions: {
        display: 'none',
        justifyContent: 'flex-start',
        width: '100%',
        alignItems: 'center',
        marginTop: '2%',
        '& button': {
            marginRight: '2.5%',
            textTransform: 'none',
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '&:hover': {
                backgroundColor: theme.palette.primary.light
            }
        },
        [theme.breakpoints.up(MOBILE)]: {
            display: 'flex'
        }
    },
    padd1: {
        padding: '1rem',
        width: '100%'
    },
    picker: {
        '& .MuiButtonBase-root .MuiIconButton-root .MuiPickersDay-day .MuiPickersDay-hidden .MuiPickersDay-dayDisabled': {
            width: '50px',
            height: '50px'
        }
    },
    timeChooseTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    timeChange: {
        color: theme.palette.blue.main,
        marginLeft: '1rem',
        background: 'transparent',
        textTransform: 'none',
        fontSize: '14px',
        '&:hover': {
            background: 'none'
        }
    },
    fadeTop: {
        background: 'linear-gradient(180deg, rgba(250,250,250,1) 50%, rgba(250,250,250,0.1) 100%)',
        position: 'sticky',
        marginBottom: -80,
        height: 80,
        width: '98.5%',
        opacity: 0.9
    },
    fadeBottom: {
        background: 'linear-gradient(0deg, rgba(250,250,250,1) 50%, rgba(250,250,250,0.1) 100%)',
        position: 'sticky',
        marginTop: -80,
        height: 80,
        width: '98.5%',
        opacity: 0.9
    }
});

export const calendar = theme => ({
    actions: {
        '& button': {
            marginRight: '2.5%',
            textTransform: 'none',
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '&:hover': {
                backgroundColor: theme.palette.primary.light
            }
        },
        [theme.breakpoints.up(theme.breakpoints.mobile)]: {
            display: 'flex'
        }
    },
    todayActive: {
        backgroundColor: '#4a90e2',
        color: 'white',
        borderRadius: '2rem',
        textDecoration: 'underline'
    },
    todayInactive: {
        backgroundColor: 'white',
        color: 'black',
        borderRadius: '2rem',
        textDecoration: 'underline'
    }
});

export const timeout = () => ({
    dialog: {
        '& .MuiDialog-paper': {
            padding: '1rem 0'
        }
    },
    title: {
        alignSelf: 'center',
        '& h2': {
            fontSize: '1.3rem'
        },
        '& p': {
            textAlign: 'center'
        }
    },
    description: {
        color: 'black',
        textAlign: 'center'
    },
    btn: {
        backgroundColor: '#3083EC',
        color: 'white',
        padding: '0.5rem 1rem',
        fontSize: '0.7rem',
        '&:hover': {
            backgroundColor: '#0c62cf'
        }
    }
});

export const noAvailabilityModalStyles = theme => ({
    button: {
        textTransform: 'none',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        '&:hover': {
            backgroundColor: theme.palette.primary.light
        },
        margin: '.4rem'
    },
    text: {
        fontSize: '1rem',
        color: theme.palette.black.main,
        textAlign: 'center'
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: '50vh'
    },
    mobileActions: {
        textAlign: 'center'
    }
});
