import {
    APPOINTMENTS_FETCHED,
    ISFETCHINGAPPOINTMENTS,
    AUTH_USER,
    CHANGE_MODAL_VISIBILITY,
    CHANGE_USER_FORM_DATA,
    LOGOUT,
    UPDATE_USER,
    VALIDATE_USER_FORM,
    VALIDATE_IS_FORM_DIRTY,
    SET_ORGANISATION
} from '.';
import AppointmentApi from '../../api/AppointmentApi';
import CustomerApi from '../../api/customerApi';
import CreditCardApi from '../../api/creditCardApi';
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';

const isFetchingAppointments = payload => async dispatch => {
    return dispatch({
        type: ISFETCHINGAPPOINTMENTS,
        payload
    });
};

export const authUser = payload => async dispatch => {
    return dispatch({
        type: AUTH_USER,
        payload
    });
};

export const updateUser = payload => async dispatch => {
    await Promise.all(
        (payload?.customer?.cards || []).map(async card => {
            if (!card.addingCard) return;
            const data = {
                cardNonce: card.nonce,
                customerId: payload.id,
                isDefaultCard: card.isDefaultCard || false
            };

            await CreditCardApi.addCreditCard(data);
        })
    );

    const deletedCards = (payload?.customer?.deletedCards || []).map(card => card.id);

    if (deletedCards.length > 0) {
        await CreditCardApi.deleteCreditCards(deletedCards, payload.id);
    }

    CustomerApi.updateUser(payload.id, _.omit(payload.customer, 'id')).then(payload => {
        toastr.success('Data saved successfully');
        return dispatch({
            type: UPDATE_USER,
            payload
        });
    });
};

export const changeModalVisibility = payload => dispatch => {
    return dispatch({
        type: CHANGE_MODAL_VISIBILITY,
        payload
    });
};

export const validateUserForm = payload => dispatch => {
    return dispatch({
        type: VALIDATE_USER_FORM,
        payload
    });
};

export const validateIsFormDirty = payload => dispatch => {
    return dispatch({
        type: VALIDATE_IS_FORM_DIRTY,
        payload
    });
};

export const changeUserFormData = payload => dispatch => {
    return dispatch({
        type: CHANGE_USER_FORM_DATA,
        payload
    });
};

export const logout = () => dispatch => {
    localStorage.removeItem('token');
    return dispatch({
        type: LOGOUT
    });
};

export const getAppointment = customerId => async dispatch => {
    dispatch(isFetchingAppointments(true));
    const data = await AppointmentApi.getAppointmentsCustomer(customerId);
    if (!data) {
        return;
    }
    dispatch({
        type: APPOINTMENTS_FETCHED,
        payload: (data.appointments || []).filter(appt => !appt.isCanceled)
    });
    dispatch(isFetchingAppointments(false));
};

export const deleteAppointment = (customerId, appointmentId) => async dispatch => {
    await AppointmentApi.removeAppointment(appointmentId);
    dispatch(getAppointment(customerId));
};

export const setOrganisaton = payload => dispatch => {
    dispatch({
        type: SET_ORGANISATION,
        payload
    });
};
