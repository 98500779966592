import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import AppointmentApi from '../../api/AppointmentApi';
import { getState, getCurrentLocation, getCurrentAppointments } from '../../redux/selectors/book-appointment';
import { getCurrentTimezonedDate } from '../../collums-components/helpers/timezone';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';

export const useAvailableDays = setIsLoading => {
    const [month, setMonth] = useState(getCurrentTimezonedDate().getMonth());
    const [year, setYear] = useState(getCurrentTimezonedDate().getFullYear());
    const [availableDays, setAvailableDays] = useState([]);

    const state = useSelector(getState);
    const location = useSelector(getCurrentLocation);
    const isReschedule = useSelector(state => state.bookAppointment.isReschedule);
    const currentAppt = useSelector(getCurrentAppointments);
    // const user = useSelector(state => state.auth.user);

    useEffect(() => {
        if (!location.id) return;
        // Array of appointments
        let query = state.currentAppointments.map(appt => {
            return {
                service: appt.service,
                month,
                year,
                location: location.id,
                practitioner: appt.staff?.id,
                ...(state.id ? { id: state.id } : {}),
                withHolidays: true
            };
        });
        // query = [query[query.length - 1]];

        // The response will be an array. Each position of the array
        // is a working-day of month, with available days
        AppointmentApi.getAvailableDays(query)
            .then(async res => {
                if (!res) {
                    return;
                }
                if (isReschedule) {
                    // const staffDiff = [...new Set(res.map(el => el.staff))];
                    // const availableDays = res.filter((day, i)=>{
                    //     const availableDays = res.slice(i, res.length - 1).filter(el => {
                    //         return el.date === day.date;
                    //     });
                    //     return availableDays.length === staffDiff.length;
                    // }).map(days => days.date);
                    const availableDays = res.map(days => days.date);

                    const daysWithAvailableTime = availableDays.filter(day => {
                        const hoursAvailable = res.filter(el => el.date === day);
                        const apptToReschedule = moment(getCurrentTimezonedDate(currentAppt[0].event.start));
                        const linkedAppts = currentAppt.slice(1, currentAppt.length);
                        let timesToReschedule = [];
                        if (currentAppt[0].staff) {
                            timesToReschedule = hoursAvailable.find(el => el.staff === currentAppt[0].staff.id).times;
                        } else {
                            hoursAvailable.forEach(hAvailable => {
                                timesToReschedule = timesToReschedule.concat(hAvailable.times);
                            });
                        }
                        linkedAppts.forEach(appt => {
                            const apptToCompare = moment(getCurrentTimezonedDate(appt.event.start));
                            const difference = moment.duration(apptToCompare.diff(apptToReschedule)).asMinutes();
                            const timesToCheck = hoursAvailable.find(el => el.staff === appt.staff.id).times;
                            const formatTimesLinkedAppt = timesToCheck.map(time =>
                                moment(getCurrentTimezonedDate(time)).format('HH:mm')
                            );
                            timesToReschedule = timesToReschedule.filter(timeToReschedule => {
                                const formatTimeToReschedule = moment(getCurrentTimezonedDate(timeToReschedule));
                                return formatTimesLinkedAppt.includes(
                                    formatTimeToReschedule.add(difference, 'minutes').format('HH:mm')
                                );
                            });
                        });
                        return timesToReschedule.length;
                    });
                    setAvailableDays(daysWithAvailableTime.map(el => getCurrentTimezonedDate(el)));
                    setIsLoading(false);
                } else {
                    setAvailableDays(res.map(el => getCurrentTimezonedDate(el.date)));
                    setIsLoading(false);
                }
            })
            .catch(err => toastr.error(err?.data?.message || 'Something went wrong'));
        // eslint-disable-next-line
    }, [month]);

    return [availableDays, setMonth, setYear, month, state, year];
};
