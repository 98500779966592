import { MOBILE, TABLET } from '../../../constants/breakpoints';

export const selectorStyle = theme => ({
    container: {
        width: '100%',
        maxHeight: 800,
        overflow: 'auto',

        '& div + div:last-child': {
            paddingBottom: '1rem'
        },

        '& .MuiTypography-caption, .MuiTypography-body2': {
            padding: 0,
            color: '#000000DE',
            margin: 0
        },

        [theme.breakpoints.up(MOBILE)]: {
            border: '1px solid #ccc',
            width: '100%',
            borderRadius: 10
        },

        [theme.breakpoints.up(TABLET)]: {
            width: '100%'
        }
    },
    fadeTop: {
        background: 'linear-gradient(180deg, rgba(250,250,250,1) 50%, rgba(250,250,250,0.1) 100%)',
        position: 'sticky',
        marginBottom: -40,
        height: 40,
        width: '98.5%',
        opacity: 0.9,
        [theme.breakpoints.down(TABLET)]: {
            marginBottom: -10,
            height: 10
        }
    },
    fadeBottom: {
        background: 'linear-gradient(0deg, rgba(250,250,250,1) 50%, rgba(250,250,250,0.1) 100%)',
        position: 'sticky',
        marginTop: -40,
        height: 40,
        width: '98.5%',
        opacity: 0.9,
        [theme.breakpoints.down(TABLET)]: {
            marginTop: -10,
            height: 10
        }
    }
});

export const selectorRowStyle = theme => ({
    root: {
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '.5rem 1rem',
        '& span': {
            fontSize: 14
        },

        [theme.breakpoints.up(MOBILE)]: {
            border: '3px solid transparent',

            '&:hover': {
                border: '3px solid #2b78e4',
                color: '#2b78e4'
            }
        }
    }
});
