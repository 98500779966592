import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { INVOICE_ORIGIN } from '../../../../collums-constants';
import InvoiceApi from '../../../../api/invoiceApi';
import { Button, withStyles } from '@material-ui/core';
import { coherentIframeStyles as styles } from './styles';
import CoherentApi from '../../../../api/coherentApi';

let coherentIntervalId = null;

const CoherentCreditCard = ({
    classes,
    // bookingDeposit,
    // accountBalance,
    deposit,
    // price,
    handleNext,
    coherentTransactionId,
    setCoherentTransactionId
}) => {
    const customerId = useSelector(state => state.auth.user.id);
    const [coherentUrl, setCoherentUrl] = useState(null);
    // const [deposit, setDeposit] = useState(null);
    const state = useSelector(state => state.bookAppointment);

    const [draftInvoiceData, setDraftInvoiceData] = useState(null);

    useEffect(() => {
        const stepElement = document.querySelector('.MuiStepper-root');
        if (stepElement) {
            setTimeout(() => {
                stepElement.scrollIntoView({ behavior: 'instant', block: 'start' });
            }, 100);
        }
    }, []);

    useEffect(() => {
        startCoherentInterval();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coherentTransactionId]);

    /**
     * Invoice should generate once again if price, step or customer id has changed.
     */
    useEffect(() => {
        setDraftInvoiceData(null);
    }, [customerId, deposit, state.currentStep]);

    // useEffect(() => {
    //     // make sure deposit amount is set.
    //     if (!isNaN(parseFloat(bookingDeposit?.bookingDepositAmount))) {
    //         let depositValue = 0;
    //
    //         if (bookingDeposit.bookingDepositType === DEPOSIT_TYPES.PERCENTAGE) {
    //             depositValue = price * (bookingDeposit.bookingDepositAmount / 100);
    //         } else if (bookingDeposit.bookingDepositAmount) {
    //             depositValue = bookingDeposit.bookingDepositAmount;
    //         }
    //
    //         // if (accountBalance && accountBalance > 0) {
    //         //     depositValue = depositValue - accountBalance;
    //         // }
    //
    //         depositValue = Math.round(depositValue * 100) / 100;
    //
    //         if (depositValue < 0) {
    //             depositValue = 0;
    //         }
    //
    //         setDeposit(depositValue);
    //     } else {
    //         setDeposit(0);
    //     }
    // }, [bookingDeposit, price]);

    useEffect(() => {
        (async () => {
            if (deposit > 0) {
                let invoice = draftInvoiceData;
                if (!invoice) {
                    invoice = await saveInvoice();
                    if (invoice) {
                        setDraftInvoiceData(invoice);
                        // create payment, get coherent transaction url
                        const result = await addPayment(invoice);

                        if (result) {
                            if (result?.coherentUrl) {
                                setCoherentUrl(result.coherentUrl);
                            }
                            if (result?.coherentTransactionId) {
                                setCoherentTransactionId(result.coherentTransactionId);
                            }
                        }
                    }
                }
            } else if (deposit === 0) {
                handleNext();
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerId, deposit]);

    const refreshPaymentForm = async () => {
        if (draftInvoiceData) {
            const result = await addPayment(draftInvoiceData);

            if (result) {
                if (result?.coherentUrl) {
                    setCoherentUrl(result.coherentUrl);
                }
                if (result?.coherentTransactionId) {
                    setCoherentTransactionId(result.coherentTransactionId);
                }
            }
        }
    };

    const startCoherentInterval = () => {
        if (coherentTransactionId) {
            let alreadySent = false;
            coherentIntervalId = setInterval(async () => {
                const result = await CoherentApi.checkPayment(coherentTransactionId);
                if (result.isPaid && !alreadySent) {
                    clearInterval(coherentIntervalId);
                    alreadySent = true;
                    setTimeout(() => {
                        handleNext();
                    }, 3000);
                }
            }, 3000);
        }
    };

    const saveInvoice = async () => {
        const items = [
            {
                location: state.currentLocation.id,
                type: 'Account',
                name: 'Account',
                discount: 0,
                tax: 0,
                taxValue: 0,
                quantity: 1,
                finalPrice: deposit,
                grossPrice: deposit,
                netPrice: deposit,
                listPrice: deposit
            }
        ];

        const invoiceDraft = {
            customer: customerId,
            items,
            discount: 0,
            description: 'Invoice for Booking Deposit',
            origin: INVOICE_ORIGIN.SALE,
            discountProperties: undefined,
            amount: deposit,
            temporaryDiscount: undefined,
            clinic: state.currentLocation.id,
            isDraft: true
        };
        return await InvoiceApi.saveInvoice(invoiceDraft);
    };

    const addPayment = async invoice => {
        const payment = {
            notifications: { email: true, SMS: false },
            payments: [
                {
                    coherentPaymentFlag: true,
                    value: deposit,
                    discount: 0,
                    type: 'Card',
                    cardData: {}
                }
            ],
            invoice: {
                id: invoice.id,
                customer: invoice.customer,
                code: invoice.code,
                taxPercentages: 0
            }
        };

        return await InvoiceApi.addInvoicePayment(payment);
    };

    return (
        <div className={classes.wrapper}>
            {coherentUrl && (
                <>
                    <iframe id="coherent-iframe" className={classes.iframe} src={coherentUrl} title="coherent-iframe" />
                    <div className={classes.coherentActions}>
                        <Button onClick={() => refreshPaymentForm()} variant="outlined">
                            Start again
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};

export default withStyles(styles)(CoherentCreditCard);
