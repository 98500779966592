import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import { removeServiceDialogStyles as styles } from './styles';

function RemoveAppointmentDialog({ onConfirm, onCancel, open, classes }) {
    return (
        <>
            <Dialog open={!!open}>
                <DialogContent>
                    <DialogContentText className={classes.text}>
                        Remove this appointment? <br />
                        Once removed please re-confirm the date and time for your booking.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.confirm} onClick={onConfirm}>
                        Yes
                    </Button>
                    <Button className={classes.button} onClick={onCancel}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

RemoveAppointmentDialog.propTypes = {
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    open: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(RemoveAppointmentDialog);
