import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { differenceInHours } from 'date-fns';
import _ from 'lodash';

import { MOBILE } from '../../../constants/breakpoints';
import { confirm_appointment_cancellation } from '../../../constants/messages';

import { Button, useMediaQuery } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';

import Dialog from '../../common/Dialog/index';
import DropMenu from '../../common/DropMenu';

import { deleteAppointment } from '../../../redux/actions/auth';
import { appointmentFromManageAppointment } from '../../../redux/actions/book-appointment';

import { auth as authSelector } from '../../../redux/selectors/auth';
import { getCurrentLocation } from '../../../redux/selectors/book-appointment';

import AppointmentsApi from '../../../api/AppointmentApi';
import { getCurrentTimezonedDate } from '../../../collums-components/helpers/timezone';
import { LAST_CLINIC_ID } from '../../../constants/storageKeys';
import { getCurrencySymbol } from '../../../collums-components/helpers';
import { TC_MERGE_TAGS, TC_MODAL_MERGE_TAGS } from '../../../collums-constants';
import { late_cancellation_template } from '../../../collums-constants/utils/index';

const Action = ({ condition, id }) => {
    const formatCurrency = value =>
        value
            ? parseFloat(value)
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')
            : 0.0;

    const dispatch = useDispatch();
    const history = useHistory();
    const isMobile = useMediaQuery(`(max-width:${MOBILE}px)`);

    const auth = useSelector(authSelector);
    const location = useSelector(getCurrentLocation);

    const [appointment, setAppointment] = useState(null);
    const [lateCancellationFormattedText, setLateCancellationFormattedText] = useState('');

    const cancellationCharge = formatCurrency(
        useSelector(state => state.auth.organisation?.lateCancellationChargeValue || '0.00')
    );
    const cancellationType = useSelector(state => state.auth.organisation?.lateCancellationChargeType || 'AMOUNT');
    const cancellationPeriod = useSelector(state => state.auth.organisation?.lateCancellationPeriod || 48);
    const showCancelTerms = useSelector(state => state.auth.organisation?.showCancelTerms);
    const lateCancellationText = useSelector(state => state.auth.organisation?.lateCancellationText);

    const [dialogVisibility, setDialogVisibility] = useState(false);
    const [rescheduleDialogVisible, setRescheduleDialogVisible] = useState(false);
    const [confirmCancellationModal, setConfirmCancellationModal] = useState(false);

    useEffect(() => {
        let message = late_cancellation_template;
        if (lateCancellationText) {
            message = lateCancellationText;
        }

        const tagValues = {
            '{LateCancellationType}': cancellationType === 'AMOUNT' ? getCurrencySymbol() : '%',
            '{LateCancellationValue}': cancellationCharge,
            '{LateCancellationFee}':
                cancellationType === 'AMOUNT'
                    ? `${getCurrencySymbol()}${cancellationCharge}`
                    : `${cancellationCharge}%`,
            '{LateCancellationPeriod}': cancellationPeriod
        };

        [...TC_MERGE_TAGS, ...TC_MODAL_MERGE_TAGS].forEach(tag => {
            while (message.search(tag) > -1) {
                message = message.replace(tag, tagValues[tag]);
            }
        });
        setLateCancellationFormattedText(message);
    }, [cancellationPeriod, cancellationCharge, cancellationType, lateCancellationText]);

    async function handleAppointment(isBookAgain) {
        const [rooms, equipments] = (() => {
            try {
                const locations = appointment?.service?.locations;
                if (!locations) return [[], []];
                const currentLocation = locations.find(el => el.clinic === location.id);
                if (currentLocation) {
                    const rooms = currentLocation.rooms || [];
                    const equipments = currentLocation.equipments || [];
                    return [rooms, equipments];
                }
                return [[], []];
            } catch (err) {
                console.error(err);
                return [[], []];
            }
        })();

        localStorage.setItem(LAST_CLINIC_ID, appointment.clinic.id);

        const fieldLocation = {
            clinic: location.id,
            rooms,
            equipments,
            staffs: _.flatten(
                appointment.service.locations.map(location => {
                    return location.staffs.map(practitioner => {
                        const staff = {
                            id: practitioner.staff
                        };
                        return { staff };
                    });
                })
            )
        };

        const data = {
            ...appointment,
            service: {
                ...appointment.service,
                defaultPrice: appointment.service.defaultGrossPrice,
                locations: [fieldLocation]
            }
        };

        if (isBookAgain) {
            data.isBookAgain = isBookAgain;
        } else {
            data.isReschedule = true;
        }

        if (data.isLinked) {
            const hasLinkedAppointments = auth.appointments.filter(appointment => {
                return appointment.linkedAppointments.length > 0;
            });
            const appts = hasLinkedAppointments.filter(appt => {
                return appt.linkedAppointments.some(linkedAppointmentId => linkedAppointmentId === data.id);
            });
            data.linkedAppointments = [appts[0].id, ...appts[0].linkedAppointments.filter(id => id !== data.id)];
        }

        data.appointments = await Promise.all(
            data.linkedAppointments.map(async id => {
                const appt = await AppointmentsApi.getAppointment(id);
                return {
                    appointmentId: appt.id,
                    service: {
                        ...appt.service,
                        defaultPrice: appt.service.defaultGrossPrice,
                        locations: [fieldLocation]
                    },
                    staff: { ...appt.practitioner },
                    event: { ...appt.event },
                    isLinked: appt.isLinked,
                    linkedAppointments: [...appt.linkedAppointments]
                };
            })
        );
        dispatch(appointmentFromManageAppointment(data));
        history.push(
            '/step/' + data.clinic.id + '/' + data.service.category.id + '/' + data.service.id + '?reschedule=1'
        );
    }

    const handleDialogVisibility = option => setDialogVisibility(option);
    const hoursToAppointment = () =>
        differenceInHours(getCurrentTimezonedDate(appointment.event.start), getCurrentTimezonedDate());

    const handleCancelAppointment = () => {
        if (showCancelTerms && hoursToAppointment() < cancellationPeriod) {
            handleDialogVisibility(true);
            return;
        }
        setConfirmCancellationModal(true);
    };

    const handleRescheduleAppointments = () => {
        if (showCancelTerms && hoursToAppointment() < cancellationPeriod) {
            setRescheduleDialogVisible(true);
            return;
        }
        handleAppointment();
    };

    const handleCancellation = async id => {
        dispatch(deleteAppointment(auth.user.id, id));
    };

    useEffect(() => {
        const fetchAppointment = async () => {
            const appt = await AppointmentsApi.getAppointment(id);
            setAppointment(appt);
        };

        fetchAppointment();
    }, [id]);

    const handleConditionTrueRender = () => {
        if (appointment?.service.locations.find(loc => loc.clinic === appointment.clinic.id).showOnline) {
            if (isMobile) {
                return <RefreshIcon color="primary" onClick={() => handleAppointment(true)} />;
            }
            return (
                <Button variant="contained" color="primary" onClick={() => handleAppointment(true)}>
                    Book again
                </Button>
            );
        }

        return <p>Cannot be rebooked online</p>;
    };

    return condition ? (
        handleConditionTrueRender()
    ) : (
        <>
            <DropMenu
                label="Options"
                isMobile={isMobile}
                submenu={[
                    {
                        label: 'Cancel',
                        action: () => handleCancelAppointment()
                    },
                    {
                        label: 'Reschedule',
                        action: () => handleRescheduleAppointments()
                    }
                ]}
            />
            <Dialog
                title={'Late cancellation'}
                maxWidth={'xs'}
                content={lateCancellationFormattedText}
                isVisible={dialogVisibility}
                setVisibility={setDialogVisibility}
                actionOnContinue={() => handleCancellation(id)}
                actionOnCancel={() => handleDialogVisibility(false)}
            />
            <Dialog
                title={'Late appointment change'}
                maxWidth={'xs'}
                content={lateCancellationFormattedText}
                isVisible={rescheduleDialogVisible}
                setVisibility={() => setRescheduleDialogVisible(false)}
                actionOnContinue={handleAppointment}
                actionOnCancel={() => setRescheduleDialogVisible(false)}
            />
            {confirmCancellationModal && (
                <Dialog
                    title={'Confirm cancellation'}
                    maxWidth={'xs'}
                    content={confirm_appointment_cancellation}
                    isVisible={confirmCancellationModal}
                    setVisibility={() => setConfirmCancellationModal(false)}
                    actionOnContinue={() => handleCancellation(id)}
                    actionOnCancel={() => setConfirmCancellationModal(false)}
                />
            )}
        </>
    );
};

export default Action;
