import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import { aditionalServiceDialogStyles as styles } from './styles';

function AdditionalServiceDialog({ onConfirm, onCancel, open, classes }) {
    return (
        <>
            <Dialog open={!!open}>
                <DialogContent>
                    <DialogContentText className={classes.text}>Do you want to book another service?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.button} onClick={onConfirm}>
                        Yes
                    </Button>
                    <Button className={classes.button} onClick={onCancel}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

AdditionalServiceDialog.propTypes = {
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    open: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AdditionalServiceDialog);
