export const closeBtnStyles = () => ({
    button: {
        padding: 8,
        '& .material-icons': {
            width: 18,
            height: 18
        },
        '& .img': {
            width: 18,
            height: 18
        }
    }
});

export const modalStyles = theme => ({
    input: {
        '& input, & input::placeholder': {
            fontSize: '16px !important',
            fontFamily: 'Roboto, Arial, "sans-serif" !important'
        },
        '& > label': {
            transform: 'translate(14px, 19px) scale(1)'
        }
    },
    container: {
        '& .MuiDialog-paper': {
            position: 'fixed',
            top: 45,
            padding: '16px',
            maxHeight: 500,
            width: '600px'
        }
    },
    title: {
        '& > h2': {
            display: 'flex',
            fontSize: '14px'
        }
    },
    singupTitle: {
        marginTop: '8px',
        marginBottom: '12px',
        textAlign: 'justify'
    },
    inputMargin: {
        marginTop: '16px',
        '& input': {
            height: 'inherit',
            boxSizing: 'border-box'
        }
    },
    inputPhone: {
        marginBottom: '14px'
    },
    backButton: {
        color: theme.palette.primary.main,
        '&:hover': {
            cursor: 'pointer'
        },
        display: 'flex',
        fontFamily: 'Roboto',
        fontWeight: '400',
        fontSize: '14px'
    },
    button: {
        color: theme.palette.white.main,
        boxShadow: 'none',
        borderRadius: 0,
        fontSize: 14,
        textTransform: 'none',
        width: '45%'
    },
    blueButton: {
        background: theme.palette.blue.main,
        '&:hover': {
            background: theme.palette.blue.main,
            boxShadow: 'none'
        }
    },
    pinkButton: {
        background: theme.palette.pink.main,
        '&:hover': {
            background: theme.palette.pink.main,
            boxShadow: 'none'
        }
    },
    buttonsContainer: {
        marginTop: '1px',
        marginBottom: '14px'
    },
    linkColor: {
        color: theme.palette.blue.main
    },
    autoComplete: {
        '& > div > input': {
            height: 4
        }
    },
    infoBox: {
        marginTop: '8px'
    },
    info: {
        textAlign: 'justify',
        wordBreak: 'keep-all'
    },
    fontRed: {
        color: 'red'
    },
    contentText: {
        textAlign: 'justify',
        color: '#000'
    }
});

export const loginModalStyles = theme => ({
    accountCreated: {
        padding: '8px 16px',
        fontSize: '16px !important',
        textAlign: 'center'
    },
    passwordStrength: {
        marginTop: 10
    },
    input: {
        '& input, & input::placeholder': {
            fontSize: '16px !important',
            fontFamily: 'Roboto, Arial, "sans-serif" !important'
        },
        '& > label': {
            transform: 'translate(14px, 19px) scale(1)'
        }
    },
    containerSmallPaddings: {
        '& .MuiDialogContent-root': {
            padding: '8px 5px'
        }
    },
    container: {
        '& .MuiDialog-paper': {
            position: 'fixed',
            top: 45,
            padding: '16px',
            maxHeight: 500,
            width: '600px',
            maxWidth: '95% !important'
        }
    },
    title: {
        '& > h2': {
            display: 'flex',
            fontSize: '14px'
        }
    },
    singupTitle: {
        marginTop: '8px',
        marginBottom: '12px',
        textAlign: 'justify'
    },
    inputMargin: {
        marginTop: '16px',
        '& input': {
            height: 'inherit',
            boxSizing: 'border-box'
        }
    },
    inputPhone: {
        marginBottom: '14px'
    },
    backButton: {
        color: theme.palette.primary.main,
        '&:hover': {
            cursor: 'pointer'
        },
        display: 'flex',
        fontFamily: 'Roboto',
        fontWeight: '400',
        fontSize: '14px'
    },
    button: {
        color: theme.palette.white.main,
        boxShadow: 'none',
        borderRadius: 0,
        fontSize: 14,
        textTransform: 'none',
        width: '45%'
    },
    blueButton: {
        background: theme.palette.blue.main,
        '&:hover': {
            background: theme.palette.blue.main,
            boxShadow: 'none'
        }
    },
    pinkButton: {
        background: theme.palette.pink.main,
        '&:hover': {
            background: theme.palette.pink.main,
            boxShadow: 'none'
        }
    },
    buttonsContainer: {
        marginTop: '16px'
    },
    linkColor: {
        color: theme.palette.blue.main
    },
    autoComplete: {
        '& > div > input': {
            height: 4
        }
    },
    infoBox: {
        marginTop: '8px'
    },
    info: {
        textAlign: 'justify',
        wordBreak: 'keep-all'
    },
    fontRed: {
        color: 'red'
    },
    textFieldReferralSource: {
        '& .MuiFormLabel-root': {
            minHeight: '12px',
            background: 'white',
            zIndex: 100
        }
    }
});

export const changePasswordStyles = () => ({
    margin1RemTop: {
        marginTop: '16px'
    }
});
