import React, { useMemo, useState } from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles, Divider } from '@material-ui/core';

import { getCurrencySymbol, toLocaleString } from '../../collums-components/helpers/index';

import { SERVICE, STAFF, TIME } from '../../constants/steps';
import {
    changeStep,
    updateAppointments,
    changeService,
    clearLocation,
    setCurrentDate,
    setCurrentTimeStep,
    chooseTime,
    setCurrentCategory,
    availableStaff
} from '../../redux/actions/book-appointment';
import {
    getCurrentTime,
    getCurrentLocation,
    getCurrentStaff,
    getCurrentDate,
    getCurrentAppointments
} from '../../redux/selectors/book-appointment';
import { stepStyles } from './styles';
import { getAddress } from './../../utils/helpers/index';
import { getLocationItem } from '../../collums-constants/utils';
import { useHistory } from 'react-router-dom';
import RemoveAppointmentDialog from './RemoveAppointmentDialog';

export default function BookingAppointmentDetails(clinics) {
    const classes = makeStyles(theme => stepStyles(theme))();

    const dispatch = useDispatch();
    const history = useHistory();

    const currentLocation = useSelector(getCurrentLocation);
    const currentStaff = useSelector(getCurrentStaff);
    const currentTime = useSelector(getCurrentTime);
    const currentDate = useSelector(getCurrentDate);
    const currentAppointments = useSelector(getCurrentAppointments);
    const isLinked = useSelector(state => state.bookAppointment.isLinked);
    const hasLinked = useSelector(state => state.bookAppointment.linkedAppointments);
    const [showRemoveServiceDialog, setShowRemoveServiceDialog] = useState(false);
    const [serviceIndexToRemove, setServiceIndexToRemove] = useState(null);

    const renderPrice = current => {
        if (current.service?.defaultPrice === Infinity) {
            return null;
        }
        if (current.staff?.grossPrice) return toLocaleString(current.staff.grossPrice);
        return toLocaleString(current.service?.defaultPrice);
    };

    const goTo = step => {
        dispatch(changeStep(step));
    };

    const handleChangeTime = () => {
        dispatch(setCurrentDate(null));
        dispatch(chooseTime(null));
        dispatch(setCurrentTimeStep(null));
        goTo(TIME);
    };

    const handleChangeService = index => {
        const tempCurrAppts = currentAppointments;
        tempCurrAppts.splice(index, 1);
        handleChangeTime();
        dispatch(updateAppointments(tempCurrAppts));
        dispatch(setCurrentCategory(null));
        dispatch(changeService(null));
        dispatch(availableStaff([]));
        history.push(
            window.location.pathname
                .split('/')
                .slice(0, 3)
                .join('/') +
                '?service=' +
                tempCurrAppts.length
        );
        goTo(SERVICE);
    };

    const handleDeleteService = () => {
        const tempCurrAppts = currentAppointments;
        tempCurrAppts.splice(serviceIndexToRemove, 1);
        dispatch(updateAppointments(tempCurrAppts));
        setServiceIndexToRemove(null);
        setShowRemoveServiceDialog(false);
        if (tempCurrAppts.length === 0) {
            dispatch(setCurrentCategory(null));
            dispatch(changeService(null));
            history.push(
                window.location.pathname
                    .split('/')
                    .slice(0, 3)
                    .join('/') +
                    '?service=' +
                    tempCurrAppts.length
            );
            goTo(SERVICE);
        } else {
            // debugger;
            const lastAppt = tempCurrAppts[tempCurrAppts.length - 1];
            // dispatch(setCurrentDate(null));
            // dispatch(chooseTime(null));
            history.push(
                window.location.pathname
                    .split('/')
                    .slice(0, 3)
                    .join('/') +
                    `/${lastAppt.service.category.id}/${lastAppt.service.id}` +
                    '?service=' +
                    (tempCurrAppts.length - 1)
            );
            goTo(TIME);
        }
    };

    const setServiceToRemove = index => {
        setServiceIndexToRemove(index);
        setShowRemoveServiceDialog(true);
    };

    const handleDeletePractitioner = index => {
        const tempCurrAppts = currentAppointments;
        dispatch(changeService(tempCurrAppts[index].service));
        tempCurrAppts.splice(index, 1, {
            ...tempCurrAppts[index],
            staff: null
        });
        handleChangeTime();
        dispatch(updateAppointments(tempCurrAppts));
        history.push(
            window.location.pathname
                .split('/')
                .slice(0, 3)
                .join('/') +
                '/' +
                tempCurrAppts[index].service.category.id +
                '/' +
                tempCurrAppts[index].service.id +
                '/?service=' +
                index
        );
        goTo(SERVICE);
        goTo(STAFF);
    };

    const formatDate = value => {
        return `${format(value, 'HH:mma')}`;
    };

    const canShowTotal = useMemo(() => {
        if (currentAppointments?.length) {
            return currentAppointments.every(appt => appt.service?.showPriceOnline);
        }
        return false;
    }, [currentAppointments]);

    const showTotal = () => {
        const total = currentAppointments.reduce(
            (acc, curr) => (acc += curr.staff?.grossPrice ? curr.staff?.grossPrice : curr.service?.defaultPrice),
            0
        );
        if (isNaN(total)) {
            return '';
        }

        return (!currentStaff ? 'From: ' : '') + getCurrencySymbol() + parseFloat(total).toFixed(2);
    };

    const printDuration = loc => {
        const durations = loc.staffs.map(staff => {
            if (staff.duration) {
                return staff.duration;
            }
            return loc?.defaultDuration;
        });
        const minDuration = Math.min(...durations);
        const maxDuration = Math.max(...durations);
        return (minDuration !== maxDuration ? 'From ' : '') + minDuration;
    };

    return (
        <>
            {currentLocation && (
                <div className={classes.detailContainer}>
                    {currentLocation.accountName && (
                        <>
                            <div className={classes.detailItem}>
                                <b>{currentLocation.accountName}</b>
                                <div className={classes.row}>
                                    <span>{getAddress(currentLocation)}</span>
                                    {!(isLinked || (hasLinked && hasLinked.length)) &&
                                    clinics.length > 1 &&
                                    currentAppointments.length === 1 /* eslint-disable-next-line */ && (
                                            <a href="#" onClick={() => dispatch(clearLocation())}>
                                                change
                                            </a>
                                        )}
                                </div>
                            </div>
                            <Divider />
                        </>
                    )}
                    {currentAppointments.map(
                        (current, index) =>
                            current.service && (
                                <React.Fragment key={index}>
                                    <div className={classes.detailItem}>
                                        <div className={classes.row}>
                                            <b>
                                                {current.service.onlineName
                                                    ? current.service.onlineName
                                                    : current.service.name}
                                            </b>
                                            {current.service.showPriceOnline && (
                                                <b>
                                                    {!current.staff && 'From: '}
                                                    {renderPrice(current)}
                                                </b>
                                            )}
                                        </div>
                                        <div className={classes.row}>
                                            {current.service.showDurationOnline && (
                                                <span>
                                                    {(() => {
                                                        if (current.staff && current.staff.duration) {
                                                            return current.staff.duration;
                                                        }

                                                        return current.service.locations
                                                            ? printDuration(
                                                                  getLocationItem(current.service, currentLocation.id)
                                                              )
                                                            : printDuration(current.service.location);
                                                    })()}
                                                    mins
                                                </span>
                                            )}
                                            {!current.service.showDurationOnline && <span>&nbsp;</span>}
                                            <div>
                                                {/* eslint-disable-next-line */}
                                                <a onClick={() => setServiceToRemove(index)} style={{ color: 'red' }}>
                                                    remove
                                                </a>
                                                <span>&nbsp;&nbsp;</span>
                                                {!(
                                                    isLinked ||
                                                    (hasLinked && hasLinked.length)
                                                ) /* eslint-disable-next-line */ && (
                                                    <a onClick={() => handleChangeService(index)}>change</a>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {current.staff !== null && (
                                        <div className={classes.detailItem}>
                                            <div className={classes.row}>
                                                <b>
                                                    With{' '}
                                                    {current.staff ? current.staff.displayName : 'any practitioner'}
                                                </b>
                                                {!(
                                                    isLinked ||
                                                    (hasLinked && hasLinked.length)
                                                ) /* eslint-disable-next-line */ && (
                                                    <a href="#" onClick={() => handleDeletePractitioner(index)}>
                                                        change
                                                    </a>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {index === currentAppointments.length - 1 /* eslint-disable-next-line */ && (
                                        <a
                                            style={{ cursor: 'pointer', textAlign: 'left', marginLeft: 16 }}
                                            onClick={() => {
                                                dispatch(changeStep(SERVICE));
                                                dispatch(setCurrentCategory(null));
                                                dispatch(changeService(null));
                                                history.push(
                                                    window.location.pathname
                                                        .split('/')
                                                        .slice(0, 3)
                                                        .join('/') +
                                                        '?service=' +
                                                        currentAppointments.length
                                                );
                                            }}
                                        >
                                            Add another service
                                        </a>
                                    )}
                                    <Divider />
                                </React.Fragment>
                            )
                    )}
                    {currentDate && (
                        <div className={classes.detailItem}>
                            <div className={classes.row}>
                                <b>{format(currentDate, 'EEEE do MMMM yyyy')}</b>
                                {/* eslint-disable-next-line */}
                                <a href="#" onClick={() => handleChangeTime()}>
                                    change
                                </a>
                            </div>

                            {currentTime && (
                                <>
                                    <span>{formatDate(currentTime)}</span>
                                    <Divider />
                                </>
                            )}
                        </div>
                    )}
                    {currentAppointments && (
                        <div className={classes.detailItem}>
                            <div className={classes.row}>
                                <b>Total</b>
                                {canShowTotal && <b>{showTotal()}</b>}
                            </div>
                        </div>
                    )}
                </div>
            )}

            <RemoveAppointmentDialog
                open={showRemoveServiceDialog}
                onConfirm={handleDeleteService}
                onCancel={() => setShowRemoveServiceDialog(false)}
            />
        </>
    );
}
