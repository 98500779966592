import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import styles from './styles';
import AppointmentApi from '../../../api/AppointmentApi';
import { useHistory } from 'react-router-dom';
import { getCurrentAppointments, getCurrentConfirmStep } from '../../../redux/selectors/book-appointment';
import { CREDIT_CARD } from '../../../constants/steps';
import {
    DEPOSIT_MULTISERVICE_RULE,
    DEPOSIT_RULE,
    DEPOSIT_TYPES,
    TC_MERGE_TAGS
} from '../../../collums-constants/index';
import { setCurrentConfirmStep } from '../../../redux/actions/book-appointment';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';
import { Promise } from 'bluebird';
import { getCurrentTimezonedDate } from '../../../collums-components/helpers/timezone';
import { addMinutes } from 'date-fns/esm';

// import { DISCONNECT } from '../../../constants/socket';
import OrganisationApi from './../../../api/organisationApi';
import LoadingScreen from '../../../collums-components/components/common/loadingScreen';
import { getCurrencySymbol } from '../../../collums-components/helpers';
import { LAST_CLINIC_ID } from '../../../constants/storageKeys';
import AuthApi from '../../../api/authApi';
import { authUser } from '../../../redux/actions/auth';
import AppointmentsApi from '../../../api/AppointmentApi';
// import { differenceInHours } from 'date-fns';
import ServicesApi from '../../../api/servicesApi';
import CoherentCreditCard from './coherentCreditCardForm/CoherentCreditCard';

function Confirm({ classes }) {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [nextEnabled, setNextEnabled] = useState(false);
    const organisation = useSelector(state => state.auth.organisation);
    const renderCards = useSelector(getCurrentConfirmStep);
    // const cancellationPeriod = useSelector(state => state.auth.organisation?.lateCancellationPeriod || 48);
    const [termsAndConditionsText, setTermsAndConditionsText] = useState('');
    const boxReference = useRef(null);

    const history = useHistory();

    const [isCardRequired, setIsCardRequired] = useState(false);
    // const [bookingDeposit, setBookingDeposit] = useState(false);
    const [depositCalculated, setDepositCalculated] = useState(false);
    const [depositValue, setDepositValue] = useState(0);
    const state = useSelector(state => state.bookAppointment);
    const { accountBalance } = useSelector(state => state.auth.userFormData);
    const me = useSelector(state => state.auth);
    const currentAppointments = useSelector(getCurrentAppointments);
    // const [price, setPrice] = useState(null);
    const [coherentTransactionId, setCoherentTransactionId] = useState(null);

    // const getPrice = () => {
    //     return currentAppointments.reduce((acc, appt) => {
    //         if (appt.service?.defaultPrice === Infinity) {
    //             return acc;
    //         }
    //         if (appt.staff?.grossPrice) {
    //             return acc + appt.staff.grossPrice;
    //         }
    //         return acc + appt.service?.defaultPrice;
    //     }, 0);
    // };

    const loadData = () => {
        const stepElement = document.querySelector('.MuiStepper-root');
        if (stepElement) {
            setTimeout(() => {
                stepElement.scrollIntoView({ behavior: 'instant', block: 'start' });
            }, 100);
        }
        (async () => {
            await AuthApi.getMe()
                .then(user => {
                    dispatch(authUser(user));
                })
                .catch(() => {
                    return false;
                });
            if (!me?.userFormData?.id) {
                return false;
            }

            // setPrice(getPrice());
            const cardRequirement = await OrganisationApi.checkCardRequirement();
            setIsCardRequired(cardRequirement);
            const {
                bookingDepositType,
                bookingDepositAmount,
                bookingDepositMultipleServicesRule,
                bookingDepositRule
            } = await OrganisationApi.getPublicOrganisation();

            let summaryDepositValue = 0;

            for (const app of currentAppointments) {
                let depositValue = 0;
                let currentBookingDepositType = bookingDepositType;
                let currentBookingDepositAmount = bookingDepositAmount;
                const serviceId = app?.service?.id;
                if (currentBookingDepositType && serviceId) {
                    const service = await ServicesApi.getService(serviceId);
                    if (service.bookingDepositType === DEPOSIT_TYPES.NO_DEPOSIT) {
                        currentBookingDepositType = null;
                        currentBookingDepositAmount = null;
                    }
                    if ([DEPOSIT_TYPES.PERCENTAGE, DEPOSIT_TYPES.FIXED_AMOUNT].includes(service.bookingDepositType)) {
                        currentBookingDepositType = service.bookingDepositType;
                        currentBookingDepositAmount = service.bookingDepositAmount;
                    }
                }
                if (!isNaN(parseFloat(currentBookingDepositAmount))) {
                    if (currentBookingDepositType === DEPOSIT_TYPES.PERCENTAGE) {
                        let price = 0;
                        if (app.staff?.grossPrice) {
                            price = app.staff?.grossPrice;
                        } else {
                            price = app.service?.defaultPrice;
                        }
                        depositValue = price * (currentBookingDepositAmount / 100);
                    } else if (currentBookingDepositAmount) {
                        depositValue = currentBookingDepositAmount;
                    }

                    depositValue = Math.round(depositValue * 100) / 100;

                    if (bookingDepositMultipleServicesRule === DEPOSIT_MULTISERVICE_RULE.SINGLE) {
                        if (depositValue > 0 && depositValue > summaryDepositValue) {
                            summaryDepositValue = depositValue;
                        }
                    } else if (depositValue > 0) {
                        summaryDepositValue += depositValue;
                    }
                }
            }
            if (summaryDepositValue > 0) {
                if (bookingDepositRule === DEPOSIT_RULE.ACCOUNT_DIFFERENCE) {
                    const accountDifference = summaryDepositValue - accountBalance;
                    if (accountDifference > 0) {
                        summaryDepositValue = accountDifference;
                    } else {
                        summaryDepositValue = 0;
                    }
                }
            }
            setDepositValue(summaryDepositValue);
            setDepositCalculated(true);
        })();
    };

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (me?.userFormData?.id && !depositCalculated) {
            loadData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [me?.userFormData?.id]);

    useEffect(() => {
        if (organisation && organisation.termsAndConditionsText) {
            const tagValues = {
                '{LateCancellationType}':
                    organisation.lateCancellationChargeType === 'AMOUNT' ? getCurrencySymbol() : '%',
                '{LateCancellationValue}': organisation.lateCancellationChargeValue,
                '{LateCancellationPeriod}': organisation.lateCancellationPeriod
            };

            let message = organisation.termsAndConditionsText;
            TC_MERGE_TAGS.forEach(tag => {
                while (message.search(tag) > -1) {
                    message = message.replace(tag, tagValues[tag]);
                }
            });
            setTermsAndConditionsText(message);
        }
    }, [organisation]);

    // useEffect(() => {
    //     return () => {
    //         if (socket) {
    //             socket.send({
    //                 type: DISCONNECT
    //             });
    //             socket.close();
    //         }
    //     };
    // }, [socket]);

    const handleOnScroll = e => {
        const OFFSET_MARGIN = 5;
        const { currentTarget } = e;
        if (currentTarget.offsetHeight + currentTarget.scrollTop >= currentTarget.scrollHeight - OFFSET_MARGIN) {
            setNextEnabled(true);
        }
    };

    const redirectToCreditCardStep = () => dispatch(setCurrentConfirmStep(CREDIT_CARD));

    const redirectToLocationStep = () => {
        setIsLoading(false);
        const currentLocationId = state.currentLocation?.id;
        if (currentLocationId) {
            localStorage.setItem(LAST_CLINIC_ID, currentLocationId);
        }
        history.replace('/location');
    };

    const rescheduleAppointment = async () => {
        try {
            const apptToReschedule = moment(getCurrentTimezonedDate(state.currentAppointments[0].event.start));
            await Promise.map(
                state.currentAppointments.filter(appts => !appts.isLinked),
                async appt => {
                    const apptToCompare = moment(getCurrentTimezonedDate(appt.event.start));
                    const difference = moment.duration(apptToCompare.diff(apptToReschedule)).asMinutes();
                    const startTime = addMinutes(state.currentTime, difference);
                    const data = {
                        currentTime: startTime,
                        currentAppointment: appt,
                        currentStaff: appt.staff,
                        currentLocation: {
                            ...state.currentLocation
                        }
                    };
                    const response = await AppointmentApi.rescheduleAppointment(appt.appointmentId, data);
                    if (response.error) {
                        throw new Error(response.message);
                    }
                }
            );
        } catch (errorMessage) {
            if (errorMessage.message) {
                toastr.error(errorMessage.message);
                return errorMessage.message || '';
            }
            if (errorMessage.data?.message) toastr.error(errorMessage.data?.message);
            return errorMessage.data?.message || '';
        } finally {
            setIsLoading(false);
        }
    };

    const handleShowCreditCards = async () => {
        try {
            setNextEnabled(false);
            const currentAppointments = state.currentAppointments;
            if (state.isReschedule && currentAppointments?.length && currentAppointments[0]?.appointmentId) {
                const appointment = await AppointmentsApi.getAppointment(currentAppointments[0]?.appointmentId);

                if (appointment) {
                    // const hoursToAppointment = () => differenceInHours(getCurrentTimezonedDate(appointment.event.start), getCurrentTimezonedDate());
                    // if (hoursToAppointment() > cancellationPeriod) {
                    const rescheduleError = await rescheduleAppointment();
                    if (!rescheduleError) {
                        redirectToLocationStep();
                    }
                    return;
                    // }
                }
            }

            if (isCardRequired || depositValue > 0) {
                redirectToCreditCardStep();
                return;
            }

            setIsLoading(true);
            const saveApptResponse = await AppointmentApi.saveAppointment(state);
            setNextEnabled(true);

            if (saveApptResponse.error) {
                toastr.error(saveApptResponse.message);
                return;
            }

            redirectToLocationStep();
        } catch (err) {
            toastr.error(err.message);
            redirectToCreditCardStep();
        } finally {
            setNextEnabled(true);
            setIsLoading(false);
        }
    };

    const handleNext = async () => {
        setIsLoading(true);
        if (state.isReschedule) {
            try {
                const rescheduleError = await rescheduleAppointment();
                if (!rescheduleError) {
                    redirectToLocationStep();
                }
            } catch (e) {
                console.error(e);
                setIsLoading(false);
            }
            return true;
        } else {
            try {
                const response = await AppointmentApi.saveAppointment({ ...state }, coherentTransactionId);
                setIsLoading(false);

                if (response.error) throw new Error(response.message);

                redirectToLocationStep();
            } catch (errorMessage) {
                console.error(errorMessage);
                toastr.error(errorMessage.message);
                setIsLoading(false);
            }
        }
    };

    useEffect(() => {
        if (termsAndConditionsText.length) {
            const OFFSET_MARGIN = 5;
            const { current } = boxReference;
            if (current && current.offsetHeight + current.scrollTop >= current.scrollHeight - OFFSET_MARGIN) {
                setNextEnabled(true);
            }
        }
    }, [termsAndConditionsText]);

    return (
        <>
            {isLoading && <LoadingScreen />}
            {!renderCards && (
                <div className={classes.confirmStepWrapper}>
                    <div className={classes.confirmWrapper}>
                        <Box className={classes.confirmBox} ref={boxReference} onScroll={handleOnScroll} mr={2}>
                            <div className={classes.confirmBoxTitle}>
                                <Typography variant="h2" className={classes.confirmBoxSectionTitle}>
                                    Important Information
                                </Typography>
                            </div>

                            <div
                                className={classes.termsAndConditionsDiv}
                                dangerouslySetInnerHTML={{ __html: termsAndConditionsText }}
                            />
                        </Box>
                        <div className={classes.agreeButtonWrapper} style={{ marginBottom: 40 }}>
                            <Button
                                variant="contained"
                                className={classes.agreeButton}
                                onClick={handleShowCreditCards}
                                disabled={!nextEnabled || !depositCalculated}
                            >
                                I understand and agree to these terms
                            </Button>
                        </div>
                    </div>
                </div>
            )}
            {renderCards === CREDIT_CARD && (
                <CoherentCreditCard
                    classes={classes}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    // bookingDeposit={bookingDeposit}
                    deposit={depositValue}
                    accountBalance={accountBalance}
                    // price={price}
                    handleNext={handleNext}
                    coherentTransactionId={coherentTransactionId}
                    setCoherentTransactionId={setCoherentTransactionId}
                />
            )}
        </>
    );
}

Confirm.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Confirm);
