import api from './api';
import { addMinutes } from 'date-fns/esm';
import { getUTCISOString } from '../collums-components/helpers/timezone';
import { getLocationItem } from '../collums-constants/utils';

const createLinkedAppointments = (currentTime, currentAppointments, currentLocation, currentStaff) => {
    //todo change API
    // eslint-disable-next-line
    let firstServiceStartTime = new Date(currentTime.getTime());
    let otherServicesDuration = 0;
    /* eslint-disable array-callback-return */
    const linkedAppointments = currentAppointments.map((appointment, index) => {
        const startTime = addMinutes(firstServiceStartTime, otherServicesDuration);
        const currentAppointmentDuration =
            appointment.staff.duration || getLocationItem(appointment.service, currentLocation.id)?.defaultDuration;
        otherServicesDuration += Number(currentAppointmentDuration);
        const endTime = addMinutes(startTime, currentAppointmentDuration);

        if (index !== 0) {
            return {
                event: {
                    start: getUTCISOString(startTime),
                    end: getUTCISOString(endTime)
                },
                price: appointment.staff.grossPrice || appointment.service.defaultPrice,
                clinic: currentLocation.id,
                practitioner: (appointment.staff || currentStaff).id,
                practitioners: [(appointment.staff || currentStaff).id],
                service: appointment.service.id,
                isLinked: true,
                type: 'Appointment'
            };
        }
    });
    /* eslint-enable array-callback-return */

    return linkedAppointments.filter(appt => appt !== undefined);
};

class AppointmentApi {
    static getAvailableDays(data) {
        return api
            .request({
                method: 'post',
                url: '/appointments/days-available-by-month',
                data
            })
            .then(res => res)
            .catch(console.error);
    }

    static getAppointmentsCustomer(customerId) {
        return api
            .request({
                method: 'get',
                url: `/appointments/customer/${customerId}?pageSize=${100}&group=ALL`
            })
            .then(res => res)
            .catch(console.error);
    }

    static saveAppointment(
        { currentTime, currentAppointments, currentStaff, currentLocation, cardInfo },
        coherentTransactionId
    ) {
        const startEvent = currentTime;
        const appointmentDuration =
            currentAppointments[0].staff.duration ||
            getLocationItem(currentAppointments[0].service, currentLocation.id)?.defaultDuration;
        const endEvent = addMinutes(currentTime, appointmentDuration);
        const staff = currentAppointments[0].staff || currentStaff;

        const servicelocation = getLocationItem(currentAppointments[0].service, currentLocation.id);

        const data = {
            appointment: {
                event: {
                    start: getUTCISOString(startEvent),
                    end: getUTCISOString(endEvent)
                },
                price:
                    currentAppointments[0].staff?.grossPrice ||
                    servicelocation.grossPrice ||
                    currentAppointments[0].service.defaultPrice,
                tax: servicelocation.taxType || currentAppointments[0].service.taxType,
                grossPrice:
                    currentAppointments[0].staff?.grossPrice ||
                    servicelocation.grossPrice ||
                    currentAppointments[0].service.defaultPrice,
                netPrice:
                    currentAppointments[0].staff?.netPrice ||
                    servicelocation.netPrice ||
                    currentAppointments[0].service.defaultNetPrice,
                practitioner: staff?.id,
                clinic: currentLocation.id,
                service: currentAppointments[0].service.id,
                linkedAppointments: createLinkedAppointments(
                    currentTime,
                    currentAppointments,
                    currentLocation,
                    currentStaff
                )
            },
            paymentDetails: cardInfo,
            coherentTransactionId
        };
        return api
            .request({
                method: 'post',
                url: '/appointments/public',
                data
            })
            .then(res => res)
            .catch(err => err.data);
    }

    static getAppointment(id) {
        return api.request({
            method: 'GET',
            url: `/appointments/${id}`
        });
    }

    static removeAppointment(appointmentId) {
        return api.request({
            method: 'PUT',
            url: `/appointments/cancel-by-customer/${appointmentId}`
        });
    }

    static rescheduleAppointment(id, { currentTime, currentAppointment, currentStaff, currentLocation }) {
        const startEvent = currentTime;
        const appointmentDuration =
            currentAppointment.staff.duration ||
            getLocationItem(currentAppointment.service, currentLocation.id)?.defaultDuration;
        const endEvent = addMinutes(currentTime, appointmentDuration);
        const staff = currentAppointment.staff || currentStaff;
        return api
            .request({
                method: 'PUT',
                url: `/appointments/reschedule/${id}`,
                data: {
                    appointment: {
                        event: {
                            start: getUTCISOString(startEvent),
                            end: getUTCISOString(endEvent)
                        },
                        price: staff.grossPrice,
                        netPrice: staff.netPrice,
                        grossPrice: staff.grossPrice,
                        practitioner: staff.id,
                        clinic: currentLocation.id,
                        service: currentAppointment.service.id,
                        linkedAppointments: currentAppointment.linkedAppointments
                    }
                }
            })
            .then(res => res)
            .catch(err => err.data);
    }
}
export default AppointmentApi;
