import React, { useState, useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, withStyles, useMediaQuery, CircularProgress } from '@material-ui/core';

import { format } from 'date-fns';

import { toSeconds } from '../../../utils/helpers/index';
import { changeModalVisibility } from '../../../redux/actions/auth';
import { fetchTime, setCurrentConfirmStep } from '../../../redux/actions/book-appointment';
import { chooseTime, changeStaff, changeStep, updateAppointments } from '../../../redux/actions/book-appointment';
import { useServicesDuration } from '../../../utils/hooks/bookAppointment';
import {
    getCurrentAppointments,
    getCurrentDate,
    getCurrentTime,
    getTimes,
    getCurrentLocation
} from '../../../redux/selectors/book-appointment';

import Timeout from './Timeout';

import { CONFIRM } from '../../../constants/steps';
import { time as timeStyle } from './styles';

// import { TIME_IO_FROM_FRONT } from '../../../constants/socket';
import { MOBILE } from '../../../constants/breakpoints';

import { fetchPractitioners } from '../../../api/practitionerApi';
import { getCurrentTimezonedDate } from '../../../collums-components/helpers/timezone';
import { addMinutes } from 'date-fns/esm';

let interval;

const TimeChoose = ({ socket, classes }) => {
    const dispatch = useDispatch();

    const [displayFade, setDisplayFade] = useState({
        top: false,
        bottom: true
    });

    const times = useSelector(getTimes) || [];
    const user = useSelector(state => state.auth.user);
    const isReschedule = useSelector(state => state.bookAppointment.isReschedule);
    const currentDate = useSelector(getCurrentDate);
    const currentTime = useSelector(getCurrentTime);
    const currentAppt = useSelector(getCurrentAppointments);
    const currentLocation = useSelector(getCurrentLocation);

    const history = useHistory();
    const { url } = useRouteMatch();

    const scrollerRef = useRef();
    const servicesDuration = useServicesDuration();
    const isMobile = useMediaQuery(`(max-width:${MOBILE}px)`);

    const [staff, setStaff] = useState([]);
    const [countdown, setCountdown] = useState(300);
    const [selectedHour, setSelectedHour] = useState(null);
    const [isLoginOpen, setIsLoginOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [initialApptState] = useState(currentAppt);

    useLayoutEffect(() => {
        if (!scrollerRef.current) return;

        const { scrollTop, offsetHeight, scrollHeight } = scrollerRef.current;

        if (scrollTop > 0) {
            setDisplayFade({ ...displayFade, top: true });
        } else {
            setDisplayFade({
                top: false,
                bottom: true
            });
        }

        if (scrollTop + offsetHeight >= scrollHeight) {
            setDisplayFade({
                ...displayFade,
                bottom: false
            });
        }

        if (scrollTop > 0 && scrollTop + offsetHeight < scrollHeight) {
            setDisplayFade({
                top: true,
                bottom: true
            });
        }
        // eslint-disable-next-line
    }, [scrollerRef.current?.scrollTop]);

    const handleCountdown = useCallback(() => {
        interval = setInterval(() => {
            setCountdown(current => {
                if (current === 0) {
                    setSelectedHour(null);
                    dispatch(chooseTime(null));
                    clearInterval(interval);

                    // When time countdown ends, clear the selected time in back-end
                    // socket.send({
                    //     type: TIME_IO_FROM_FRONT,
                    //     payload: null
                    // });

                    return current;
                }
                return current - 1;
            });
        }, 1000);
        /*eslint-disable-next-line */
    }, [socket]);

    useEffect(() => {
        return () => {
            clearInterval(interval);
        };
    }, [socket]);

    useEffect(() => {
        if (isLoginOpen) {
            dispatch(changeStep(CONFIRM));
        }
    }, [user, dispatch, isLoginOpen]);

    useEffect(() => {
        if (!currentLocation.id) return;

        const apptsIds = currentAppt.map(appt => appt.appointmentId);
        const data = currentAppt.map((appt, index) => {
            return {
                ...(appt.staff && { practitioner: appt.staff.id }),
                appointmentBeingReschedule: [...apptsIds],
                date: currentDate,
                service: appt.service,
                clinic: currentLocation.id,
                user: user.id,
                withHolidays: true,
                forceInterval: index === 0 ? null : 5
            };
        });

        dispatch(fetchTime(data, setIsLoading));
    }, [dispatch, currentDate, currentLocation.id, servicesDuration, currentAppt, user.id]);

    useEffect(() => {
        if (!currentLocation.id) return;
        if (!currentAppt || currentAppt.length === 0) return;

        const service = currentAppt[0]?.service;
        if (!service) return;

        const fetch = async () => {
            const payload = {
                month: getCurrentTimezonedDate().getMonth(),
                clinic: currentLocation.id,
                service
            };

            const data = await fetchPractitioners(payload);
            setStaff(data);
        };

        fetch();
    }, [currentLocation.id, dispatch, currentAppt]);

    const getSelectedStaff = (hour, index = 0) => {
        const currentStaff = { ...staff.find(staff => staff.id === hour.practitioner.id) };
        if (
            index !== 0 &&
            currentStaff &&
            Array.isArray(hour.otherDurations) &&
            hour.otherDurations.length > index - 1
        ) {
            currentStaff.duration = hour.otherDurations[index - 1];
        }
        return currentStaff;
    };

    const formatDate = value => {
        const date = getCurrentTimezonedDate(value);
        const pattern = 'HH:mma';

        const formatedDate = format(date, pattern);
        return formatedDate;
    };

    const renderTimeOptions = () => {
        // if (currentAppt.length > 1 && times && times.length) {
        //     const apptToReschedule = moment(getCurrentTimezonedDate(currentAppt[0].event.start));
        //     const linkedAppts = currentAppt.slice(1, currentAppt.length);
        //     if (times.length < 1) {
        //         return false;
        //     }
        //     let timesToReschedule = times.filter(time => time[0].practitioner.id === currentAppt[0].staff.id)[0];
        //     linkedAppts.forEach(linkedAppts => {
        //         const apptToCompare = moment(getCurrentTimezonedDate(linkedAppts.event.start));
        //         const difference = moment.duration(apptToCompare.diff(apptToReschedule)).asMinutes();
        //         const linkedTimes = times.filter(time => time[0].practitioner.id === linkedAppts.staff.id)[0];
        //         const formatTimesLinkedAppt = linkedTimes.map(time =>
        //             moment(getCurrentTimezonedDate(time.value)).format('HH:mm')
        //         );
        //         timesToReschedule = timesToReschedule.filter(timeToReschedule => {
        //             const formatTimeToReschedule = moment(getCurrentTimezonedDate(timeToReschedule.value));
        //             return formatTimesLinkedAppt.includes(
        //                 formatTimeToReschedule.add(difference, 'minutes').format('HH:mm')
        //             );
        //         });
        //     });
        //     return ((timesToReschedule && timesToReschedule.length && timesToReschedule) || []).map((el, index) => {
        //         const date = getCurrentTimezonedDate(el.value);
        //         return (
        //             <div
        //                 key={index}
        //                 className={`${classes.hourOption} ${selectedHour &&
        //                     date.getTime() === selectedHour.value.getTime() &&
        //                     classes.selectedOption} ${!el.valid && classes.disabledOption}`}
        //                 onClick={() => {
        //                     if (el.valid) {
        //                         handleSelectedHour({
        //                             ...el,
        //                             value: date
        //                         });
        //                     }
        //                 }}
        //             >
        //                 {`${formatDate(el.value)}`}
        //             </div>
        //         );
        //     });
        // }

        let combinedTimes = (times && times.length && times[0] !== undefined && times[0].length && times[0]) || [];
        if (combinedTimes.length && times.find(t => t.length === 0)) {
            return [];
        }

        let otherServicesDuration = 0;

        for (let i = 1; i < times.length; i++) {
            const currentServicesTimes = times[i];
            // eslint-disable-next-line no-loop-func
            combinedTimes = combinedTimes.map(time => {
                if (!Array.isArray(time.otherDurations)) {
                    time.otherDurations = [];
                }
                time.otherDurations.push(currentServicesTimes[0].duration);
                return time;
            });
            // eslint-disable-next-line no-loop-func
            combinedTimes = combinedTimes.filter(time => {
                const mainServiceDuration = Number(time.duration);
                const nextAppointmentTime = addMinutes(
                    new Date(time.value),
                    mainServiceDuration + otherServicesDuration
                );
                return currentServicesTimes.find(
                    oaTime => new Date(oaTime.value).getTime() === nextAppointmentTime.getTime()
                );
            });
            otherServicesDuration += Number(currentServicesTimes[0].duration);
        }
        return combinedTimes.map((el, index) => {
            const date = getCurrentTimezonedDate(el.value);
            return (
                <div
                    key={index}
                    className={`${classes.hourOption} ${selectedHour &&
                        date.getTime() === selectedHour.value.getTime() &&
                        classes.selectedOption} ${!el.valid && classes.disabledOption}`}
                    onClick={() => {
                        if (el.valid) {
                            handleSelectedHour({
                                ...el,
                                value: date
                            });
                        }
                    }}
                >
                    {`${formatDate(el.value)}`}
                </div>
            );
        });
    };

    const handleSelectedHour = hour => {
        dispatch(setCurrentConfirmStep(null));
        handleUpdateCurrAppts(hour);
        dispatch(changeStaff(getSelectedStaff(hour)));
        dispatch(chooseTime(hour.value));
    };

    useEffect(() => {
        if (!currentTime) return;

        setSelectedHour({ value: currentTime });
    }, [currentTime]);

    const handleUpdateCurrAppts = hour => {
        const newCurrentApptsState = initialApptState.map((appt, index) => {
            if (!appt.staff) {
                return { ...appt, staff: getSelectedStaff(hour, index) };
            }
            return { ...appt, staff: appt.staff };
        });
        dispatch(updateAppointments(newCurrentApptsState));
    };

    const handleBookAppointment = () => {
        if (user.id) {
            dispatch(changeStep(CONFIRM));

            history.push(`${url}/confirm`);
        } else {
            setIsLoginOpen(true);
            dispatch(changeModalVisibility({ visible: true, blockClose: true }));
        }
        if (!isReschedule) {
            if (countdown === 0) {
                setCountdown(300);
            }

            if (!selectedHour) {
                handleCountdown();
            }
        }
    };

    return (
        <div className={classes.padd1}>
            <div className={classes.timeChoose}>
                <div className={classes.timeChooseTitle}>
                    <h3 className={classes.text} style={isMobile ? { marginTop: 0, marginBottom: 0 } : {}}>
                        {currentDate.toDateString()}
                    </h3>
                </div>
                {isMobile && displayFade.top && <div className={classes.fadeTop} />}
                <div
                    ref={scrollerRef}
                    className={['overflowVisible', classes.maxHeight].join(' ')}
                    style={isMobile ? { marginTop: 0 } : {}}
                >
                    <div className={classes.contentOptions}>
                        {isLoading ? <CircularProgress /> : renderTimeOptions()}
                    </div>
                </div>
                {isMobile && displayFade.bottom && <div className={classes.fadeBottom} />}
                <div style={{ textAlign: 'center', marginBottom: 40 }}>
                    {!!selectedHour && !isReschedule && (
                        <h3 className={classes.text}>Your appointment will be held for {toSeconds(countdown)}</h3>
                    )}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleBookAppointment}
                        disabled={!selectedHour}
                        autoFocus
                    >
                        {isReschedule ? 'Reschedule appointment' : 'Book appointment'}
                    </Button>
                </div>
            </div>

            <Timeout time={countdown} />
        </div>
    );
};

export default withStyles(timeStyle)(TimeChoose);
