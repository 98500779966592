import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Axios from 'axios';
import DateFnsUtils from '@date-io/date-fns';
import { BrowserRouter } from 'react-router-dom';

import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import Toastr from './components/common/Toastr';
import Routes from './routes';
import AuthApi from './api/authApi';
import organizationApi from './api/organisationApi';

import { authUser, setOrganisaton } from './redux/actions/auth';

import theme from './styles/theme';
import './styles/index.css';
import StagingBanner from './collums-components/components/banners/StagingBanner';
import CookieBanner from './collums-components/components/banners/CookieBanner';
import { useAutoLogout } from './utils/hooks/autoLogout';
import TagManager from 'react-gtm-module';

const isStaging = ['staging', 'local'].includes(process.env.REACT_APP_ENVIRONMENT);
const cookieAccepted = () => localStorage.getItem('cookieBanner') === 'accept';

function App() {
    const dispatch = useDispatch();
    const [isLogged, setIsLogged] = useState(false);

    // custom hook to keep track of possible changes if the user is logged
    useAutoLogout();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            (async () => {
                Axios.defaults.headers.common['Authorization'] = token;
                await AuthApi.getMe()
                    .then(user => {
                        dispatch(authUser(user));
                        setIsLogged(true);
                    })
                    .catch(() => setIsLogged(true));
            })();
        } else {
            setIsLogged(true);
        }

        // Load organization data and initialize GTM
        organizationApi.getPublicOrganisation().then(res => {
            localStorage.setItem('currency', res.currency);
            dispatch(setOrganisaton(res));
            // Initialize GTM with the key received from the API
            if (res.gtmId) {
                TagManager.initialize({ gtmId: res.gtmId });
            }
        });

        /*eslint-disable-next-line */
    }, []);

    return (
        <CssBaseline>
            <MuiThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <BrowserRouter>
                        <Toastr />
                        <div>
                            {isLogged && <Routes />}
                            {isStaging && <StagingBanner />}
                            {isStaging && !cookieAccepted() && <CookieBanner />}
                        </div>
                    </BrowserRouter>
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        </CssBaseline>
    );
}

export default App;
