import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import { noAvailabilityModalStyles as styles } from './styles';

function NoAvailabilityModal({ onConfirm, open, classes }) {
    return (
        <>
            <Dialog open={!!open}>
                <DialogContent>
                    <DialogContentText className={classes.text}>
                        There is no availability for your selection this month.
                        <br />
                        Go back and select &quot;Any staff&quot; and/or remove some services to increase availability.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.button} onClick={onConfirm}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

NoAvailabilityModal.propTypes = {
    onConfirm: PropTypes.func,
    open: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NoAvailabilityModal);
