import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isEqual, startOfDay, getISODay, getMonth, set, formatISO } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

import { DatePicker } from '@material-ui/pickers';
import { Box, withStyles, useMediaQuery } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import { calendar as style } from './styles';

import { MOBILE } from '../../../constants/breakpoints';
import { TIME_HOURS_AND_MINUTES } from '../../../constants/steps';

import { useAvailableDays } from '../../../utils/hooks/bookAppointment.jsx';

import { setCurrentDate, setCurrentTimeStep } from '../../../redux/actions/book-appointment';
import { getCurrentLocation } from '../../../redux/selectors/book-appointment';
import { getCurrentTimezonedDate } from '../../../collums-components/helpers/timezone';
import NoAvailabilityModal from './NoAvailabilityModal';

function Calendar({ classes }) {
    const dispatch = useDispatch();
    const isMobile = useMediaQuery(`(max-width:${MOBILE}px)`);
    const currentLocation = useSelector(getCurrentLocation);
    const [isLoading, setIsLoading] = useState(true);
    const [showAvailabilityDailog, setShowAvailabilityDailog] = useState(false);
    // 2 commas to ignore "state" value being exported
    const [availableDays, setMonth, setYear, month, , year] = useAvailableDays(setIsLoading);

    const focusedDate = useMemo(() => {
        const date = (() => {
            if (getCurrentTimezonedDate().getMonth() !== month) return 1;
            return getCurrentTimezonedDate().getDate();
        })();

        if (month !== undefined) return formatISO(set(getCurrentTimezonedDate(), { date, month: month, year: year }));
        return getCurrentTimezonedDate();
    }, [month, year]);

    const isClinicOpen = useCallback(
        date => {
            if (!currentLocation.time || !currentLocation.holidays) return false;

            let isClosed = false;
            const weekday = getISODay(date) - 1;

            isClosed = currentLocation.time[weekday].isClosed;

            const isHoliday = currentLocation.holidays.find(current => {
                return (
                    startOfDay(getCurrentTimezonedDate(current.date)).getTime() ===
                    startOfDay(getCurrentTimezonedDate(date)).getTime()
                );
            });

            if (isHoliday) {
                return isHoliday.isClosed;
            }
            return isClosed;
        },
        [currentLocation.time, currentLocation.holidays]
    );

    const shouldDisableDate = useCallback(
        date => {
            if (isClinicOpen(date)) return true;
            if (!availableDays || !availableDays.length) return true;
            return !availableDays.some(availableDate => {
                return startOfDay(availableDate).toString() === startOfDay(date).toString();
            });
        },
        [availableDays, isClinicOpen]
    );

    useEffect(() => {
        if (!isLoading && availableDays.length === 0) {
            setShowAvailabilityDailog(true);
        }
    }, [availableDays, isLoading]);

    const selectDay = date => {
        if (shouldDisableDate(date)) return;

        dispatch(setCurrentTimeStep(TIME_HOURS_AND_MINUTES));
        dispatch(setCurrentDate(date));

        // socket.send({
        //     type: CALENDAR_IO_FROM_FRONT,
        //     payload: date
        // });
    };

    return (
        <Box
            mt={1}
            mb={1.5}
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            style={isMobile ? { transform: 'scale(1.3)' } : {}}
        >
            <Box
                width="100%"
                className={classes.picker}
                display="flex"
                pl={isMobile ? 0 : 2}
                justifyContent={isMobile ? 'center' : 'flex-start'}
            >
                {isLoading ? (
                    <CircularProgress />
                ) : (
                    <DatePicker
                        onMonthChange={date => {
                            setIsLoading(true);
                            setMonth(date.getMonth());
                            setYear(date.getFullYear());
                        }}
                        onChange={selectDay}
                        disablePast
                        openTo="date"
                        disableToolbar={isMobile}
                        renderDay={(day, _selectedDate, dayInCurrentMonth, dayComponent) => {
                            const referenceDay = startOfDay(getCurrentTimezonedDate(day));
                            if (
                                isEqual(referenceDay, startOfDay(getCurrentTimezonedDate(_selectedDate))) &&
                                getMonth(_selectedDate) !== getMonth(getCurrentTimezonedDate())
                            ) {
                                if (shouldDisableDate(day)) {
                                    return <div className={classes.todayInactive}>{day.getDate()}</div>;
                                }
                                const newDayComponent = {
                                    ...dayComponent,
                                    props: {
                                        ...dayComponent.props,
                                        selected: false
                                    }
                                };
                                return newDayComponent;
                            }
                            if (isEqual(startOfDay(getCurrentTimezonedDate()), referenceDay)) {
                                if (shouldDisableDate(day))
                                    return <div className={classes.todayInactive}>{day.getDate()}</div>;
                                return <div className={classes.todayActive}>{day.getDate()}</div>;
                            }
                            if (dayInCurrentMonth) {
                                if (shouldDisableDate(day)) return day.getDate();
                                return dayComponent;
                            }
                            return null;
                        }}
                        orientation="landscape"
                        autoOk
                        style={{ width: '800px', height: '800px' }}
                        variant="static"
                        initialFocusedDate={focusedDate}
                        value={null}
                    />
                )}
            </Box>
            <NoAvailabilityModal open={showAvailabilityDailog} onConfirm={() => setShowAvailabilityDailog(false)} />
        </Box>
    );
}
export default withStyles(style)(Calendar);
