const TITLES = {
    DAME: 'Dame',
    DR: 'Dr',
    LADY: 'Lady',
    LORD: 'Lord',
    MISS: 'Miss',
    MR: 'Mr',
    MRS: 'Mrs',
    MS: 'Ms',
    MX: 'Mx',
    PROF: 'Prof',
    SIR: 'Sir',
    CUSTOM: 'Not entered'
};

const GENDERS = {
    MALE: 'Male',
    FEMALE: 'Female',
    TRANS_MALE: 'Male trans',
    TRANS_FEMALE: 'Female trans',
    NON_BINARY: 'Non-Binary',
    CUSTOM: 'Custom'
};

const DEFAULT_PHOTO = {
    DEFAULT_MALE: 'https://www.moblab.com/images/avatar-placeholder.jpg',
    DEFAULT_FEMALE:
        'https://media.istockphoto.com/vectors/default-avatar-profile-icon-grey-photo-placeholder-hand-drawn-modern-vector-id1273297903?k=20&m=1273297903&s=170667a&w=0&h=7-nYuX1B59FiEEI4NjXiZlyFWFJYtXr_TgKEHxMdkNk='
};

const REFERRAL_SOURCES_TYPE = {
    WORD_OF_MOUTH: 'Word of mouth',
    WEBSITE: 'Website',
    INSTAGRAM: 'Instagram',
    FACEBOOK: 'Facebook',
    TWITTER: 'Twitter',
    WALK_PAST: 'Walk past',
    INTERNET: 'Internet',
    GOOGLE_SEARCH: 'Google search',
    FRIEND: 'Friend',
    DR_REFERRAL: 'Dr referral',
    CUSTOM: 'Other'
};

const NOTIFICATION_TYPES = {
    SMS: 'SMS',
    EMAIL: 'Email'
};

const PHONE_TYPE_OPTIONS = {
    WORK: 'Work',
    HOME: 'Home'
};

const RELATIONSHIPS = {
    SPOUSE: 'SPOUSE',
    PARTNER: 'PARTNER',
    PARENT: 'PARENT',
    CHILD: 'CHILD',
    FRIEND: 'FRIEND'
};

const PROFESSIONAL_BODIES = {
    GENERAL_MEDICAL_COUNCIL: 'GMC',
    NURSING_AND_MIDWIFERY_COUNCIL: 'NMC',
    GENERAL_DENTAL_COUNCIL: 'GDC',
    NONE: 'NONE'
};

const COMMISSION_RANGE_TYPES = ['product', 'service', 'course', 'total'];

const COMMISSION_CONDITIONS = {
    SELL: 'SELL',
    PERFORM: 'PERFORM',
    BOTH: 'BOTH'
};

const AMOUNT_FEE_TYPE = {
    PERCENTAGE: '%',
    AMOUNT: 'Amount'
};

const ROLES = {
    ADMIN: 'admin',
    CUSTOMER: 'customer',
    PRACTITIONER: 'practitioner'
};

const LATE_CANCELLATION_CHARGE = {
    NONE: 'NONE',
    AMOUNT: 'AMOUNT',
    PERCENTAGE: 'PERCENTAGE'
};

const SERVICE_AVAILABILITIES = {
    BOOK_AND_SELL: 'BOOK_AND_SELL',
    BOOK_ONLY: 'BOOK_ONLY',
    SELL_ONLY: 'SELL_ONLY'
};

const APPOINTMENT_TYPES = {
    APPOINTMENT: 'Appointment',
    BREAK: 'Break',
    BLOCK: 'Block',
    LEAVE: 'Leave'
};

const APPOINTMENT_REPEAT = {
    NEVER: 'never',
    EVERY_DAY: 'everyDay',
    EVERY_WEEK: 'everyWeek',
    EVERY_TWO_WEEKS: 'everyTwoWeeks',
    EVERY_MONTH: 'everyMonth'
};

const APPOINTMENT_PAYMENT_TYPES = {
    CASH: 'Cash',
    CREDIT_CARD: 'Card',
    GIFT_CARD: 'Gift Voucher'
};

const INVOICE_PAYMENT_TYPES = {
    ACCOUNT_BALANCE: 'Account',
    CREDIT_CARD: 'Card',
    GIFTCARD: 'Giftvoucher',
    CREDIT_CARD_TERMINAL: 'Card Terminal',
    BANK_TRANSFER: 'Bank transfer',
    ONLINE: 'Online',
    FOC: 'FOC',
    CUSTOM: 'Custom',
    CASH: 'Cash'
    /*CHEQUE: 'Cheque',
    DEBIT_CARD: 'Debit card'*/
};

const DEFAULT_PAYMENT_TYPES = [
    {
        name: INVOICE_PAYMENT_TYPES.CREDIT_CARD,
        label: 'Square payment',
        active: true
    },
    {
        name: INVOICE_PAYMENT_TYPES.GIFTCARD,
        label: 'Gift voucher',
        active: true
    },
    {
        name: INVOICE_PAYMENT_TYPES.ACCOUNT_BALANCE,
        label: 'Account',
        active: true
    },

    {
        name: INVOICE_PAYMENT_TYPES.CASH,
        label: 'Cash',
        active: true
    },
    {
        name: INVOICE_PAYMENT_TYPES.BANK_TRANSFER,
        label: 'Bank Transfer',
        active: true
    },
    {
        name: INVOICE_PAYMENT_TYPES.ONLINE,
        label: 'Online',
        active: true
    },
    {
        name: 'Cheque',
        label: 'Cheque',
        active: true
    }
];

const FIXED_PAYMENT_OPTIONS = [
    INVOICE_PAYMENT_TYPES.CREDIT_CARD,
    INVOICE_PAYMENT_TYPES.GIFTCARD,
    INVOICE_PAYMENT_TYPES.ACCOUNT_BALANCE
];

const APPOINTMENT_STATUS_TYPES = {
    NO_SHOW: 'No show',
    DELETED: 'Deleted',
    CANCELED: 'Cancelled',
    SCHEDULED: 'Scheduled',
    COMPLETED: 'Completed',
    RUNNING_LATE: 'Running late'
};

const INVOICE_ORIGIN = {
    SALE: 'SALE',
    REFUND: 'REFUND',
    CANCELLATION: 'CANCELLATION'
};

const STOCK_TAKE = {
    STATUS: {
        COUNT_PAUSED: 'Count paused',
        COMPLETE: 'Complete',
        STOCK_UPDATE: 'Stock update'
    }
};

const ORDER = {
    STATUS: {
        DRAFT: 'Draft',
        RAISED: 'Raised',
        AWAITING_DELIVERY: 'Awaiting delivery',
        PART_DELIVERED: 'Part delivered',
        COMPLETED: 'Completed'
    },
    HIST_STATUS: {
        PROGRESS: 'Progress',
        COMPLETE: 'Complete'
    },
    STOCK_QUANTITY_TYPE: {
        MIN_OR_LESS: 'Min stock or less',
        LESS_THAN_MAX: 'Less than max stock',
        ALL_PRODUCTS: 'All products'
    }
};

const CURRENCY_TYPES = [
    {
        NAME: 'GBP',
        LANGUAGE: 'en-GB',
        SYMBOL: '£',
        LABEL: '£ Pound'
    },
    {
        NAME: 'USD',
        LANGUAGE: 'en-US',
        SYMBOL: '$',
        LABEL: '$ USD'
    },
    {
        NAME: 'EUR',
        LANGUAGE: 'en-GB',
        SYMBOL: '€',
        LABEL: '€ Euro'
    },
    {
        NAME: 'AUD',
        LANGUAGE: 'en-AU',
        SYMBOL: '$',
        LABEL: '$ AUD'
    }
];

const GIFTCARD = {
    STATUS: {
        USED: 'Used',
        AVAILABLE: 'Available',
        EXPIRED: 'Expired',
        UNPAID: 'Unpaid',
        REFUNDED: 'Refunded'
    }
};

const APPOINTMENT_FILTER_GROUPS = {
    ALL: 'ALL',
    UPCOMING: 'UPCOMING',
    PAST: 'PAST',
    COURSES: 'COURSES',
    CANCELED: 'CANCELED',
    NO_SHOW: 'NO SHOW',
    DELETED: 'DELETED'
};

const POPUP = {
    TYPES: {
        NONE: 'None',
        CHECK_IN: 'Check in',
        CHECK_OUT: 'Check out',
        //  CLIENT_CARD: 'Client Card',
        BOOKING: 'Booking',
        ALL: 'All'
    },
    STATUS: {
        ACTIVE: 'ACTIVE',
        NO_SHOW: 'NO SHOW',
        DELETED: 'DELETED'
    }
};

const TEMPORARY_DISCOUNTS = {
    DISCOUNT_TYPES: {
        PERCENTAGE: 'Percentage',
        FIXED_AMOUNT: 'Fixed amount'
    },
    APPLY: {
        TOTAL: 'Total invoice',
        ITEM: 'Invoice line items'
    },
    ITEMS_TYPES: {
        PRODUCT: 'Product',
        COURSE: 'Course',
        SERVICE: 'Service'
    }
};

const INVOICE_PAYMENT_STATUS = {
    UNPAID: 'Unpaid',
    PART_PAID: 'Part paid',
    PAID: 'Paid',
    VOID: 'Void',
    REFUND: 'Refund'
};

const COHERENT_PAYMENT_PROCESS_STATUS = {
    WAITING: '',
    PROCESSING: 'Processing',
    DONE: 'DONE'
};

const TAXTYPES = {
    withTax: '20% VAT',
    withoutTax: 'Exempt (0%)'
};

const DISCOUNT_TYPES = {
    currency: 'currency',
    percentage: 'percentage'
};

const STATUS_ACTIVE = 10;
const STATUS_DELETED = 100;

const COURSES_GROUP = {
    all: 'All',
    active: 'Active',
    completed: 'Completed',
    expired: 'Expired / Voided'
};

const RECEIPT_STATUS = {
    DECLINED: 'DECLINED',
    REFUND: 'REFUND',
    RECEIVED: 'RECEIVED'
};

const INVOICE_ITEM_TYPES = {
    ACCOUNT: 'Account',
    COURSE: 'Course',
    GIFTCARD: 'Giftvoucher',
    MEMBERSHIP: 'Membership',
    PRE_PAID_CARD: 'Prepaid Card',
    PRODUCT: 'Product',
    SERVICE: 'Service'
};

const TEST_EMAILS = [
    'askari@townshendskinclinic.co.uk',
    'at@collums.co',
    'zurek.robert@gmail.com',
    'robert.zurek@sensilabs.pl',
    'piotr.kowalski@sensilabs.pl',
    'cezary.jagiello@sensilabs.pl',
    'slawomir.kowalski@sensilabs.pl',
    'kamil.bonislawski@sensilabs.pl',
    'kamil.bonislawski@sensilabs.pl',
    'piotr.zych@sensilabs.pl',
    'marek.swietlik@sensilabs.pl',
    'sylwia.maslankowska@sensilabs.pl'
];

const WIDGET_TYPES = {
    TEXT_WIDGET: 'Text',
    PARAGRAPH_WIDGET: 'Paragraph',
    CHECKBOX_WIDGET: 'Checkbox',
    RADIO_WIDGET: 'Radio',
    AUTOCOMPLETE_WIDGET: 'AutoComplete',
    TITLE_WIDGET: 'Title',
    SELECT_WIDGET: 'Select',
    IMAGE_WIDGET: 'Image',
    RADIO_AUTOCOMPLETE_WIDGET: 'RadioAutoComplete',
    RADIO_TEXT_WIDGET: 'RadioText',
    SIGNATURE_FIELD_WIDGET: 'SignatureField',
    LIST_WIDGET: 'List',
    RADIO_LIST_WIDGET: 'RadioList',
    TEXT_AREA_WIDGET: 'TextArea',
    BLOCK_WIDGET: 'Block'
};

const ORGANISATION_FORM_TYPES = {
    CONSENT: 'Consent',
    MEDICAL: 'Medical',
    GENERAL: 'General',
    AFTERCARE: 'Aftercare'
};

const WIDGET_GROUPS = {
    NONE: 'None',
    ALLERGIES: 'Allergies',
    DRUG_HISTORY: 'Drug History',
    MEDICAL_HISTORY: 'Medical History'
};

const IMAGE_WIDGET_ALIGNMENT = {
    LEFT: 'Left',
    CENTER: 'Center',
    RIGHT: 'Right'
};
const IMAGE_WIDGET_SIZE = {
    SMALL: 'Small',
    MEDIUM: 'Medium',
    BIG: 'Big'
};

const FORM_OPTIONS = {
    TABLETS: 'Tablets',
    CAPSULES: 'Capsules',
    CREAM: 'Cream',
    LIQUID: 'Liquid',
    SOLUTION: 'Solution for injection',
    SUPPOSITORIES: 'Suppositories',
    DROPS: 'Drops',
    OTHER: 'Other (write in notes)'
};

const PRESCRIPTION_ROUTES = {
    BUCCAL: 'Buccal',
    TOPICAL: 'Topical',
    ORAL: 'Oral',
    INTRAMUSCULAR: 'Intramuscular',
    NASAL: 'Nasal',
    OTIC: 'Otic',
    OCULAR: 'Ocular',
    RECTAL: 'Rectal',
    SUBCUTANEOUS: 'Subcutaneous',
    SUBLINGUAL: 'Sublingual',
    TRANSDERMAL: 'Transdermal',
    VAGINAL: 'Vaginal'
};

const DOSE_UNITS = {
    DOSE: 'Dose',
    GRAMS: 'g',
    MICROGRAMS: 'mcg',
    MILIGRAMS: 'mg',
    MILILITRES: 'ml',
    TABLETS: 'Tablet(s)',
    UNITS: 'Units',
    PERCENTAGE: '%'
};

const DURATION_UNITS = {
    DAYS: 'Days',
    WEEKS: 'Weeks',
    MONTHS: 'Months',
    TABLETS: 'Tablets',
    CAPSULES: 'Capsules',
    SUPPOSITORIES: 'Suppositories'
};

const TREATMENT_NOTE_TYPES = {
    MEDICAL: 'Medical Note',
    TREATMENT: 'Treatment Record'
};

const TREATMENT_TYPES = {
    GENERAL: 'General',
    TOXIN: 'Toxin',
    FILLER: 'Filler',
    LHR: 'Lightsheer',
    BLIPL: 'Basic laser / IPL'
};

const TREATMENT_AREAS = {
    CHEEKS: 'Cheeks',
    LIPS: 'Lips',
    CHIN: 'Chin',
    PERI_ORAL: 'Peri oral',
    TEAR_TROUGH: 'Tear trough',
    JAW: 'Jaw',
    NOSE: 'Nose',
    FOREHEAD: 'Forehead',
    TOP_LIP: 'Top Lip',
    OTHER: 'Other'
};

const PRODUCT_TYPES = {
    RETAIL: 'Retail',
    PROFESSIONAL: 'Professional'
};

const DILUTION_UNITS = [
    '0.5ml',
    '0.63ml',
    '1.0mls',
    '1.25mls',
    '2.0mls',
    '2.5mls',
    '3.0mls',
    '4.0mls',
    '5.0mls',
    '8.0mls'
];

const PRESCRIPTION_FREQUENCY_VALUES = {
    ONCE_DAILY: 'once daily',
    TWICE_DAILY: 'twice daily',
    THREE_TIMES_DAILY: 'three times daily',
    FOUR_TIMES_DAILY: 'four times daily',
    FIVE_TIMES_DAILY: 'five times daily',
    EVERY_FOUR_HOURS: 'every four hours',
    EVERY_MORNING: 'every morning',
    EVERY_NIGHT: 'every night',
    ONCE_WEEKLY: 'once a week',
    TWICE_WEEKLY: 'twice a week',
    THREE_TIMES_WEEKLY: 'three times a week',
    EVERY_DAY: 'every day',
    AS_REQUIRED: 'as required',
    WHEN_REQUIRED: 'when required',
    IMMEDIATELY: 'immediately',
    BEFORE_FOOD: 'before food',
    AFTER_FOOD: 'after food'
};

const PRESCRIPTION_FREQUENCY = [
    {
        abbreviated_value: 'once daily',
        value: PRESCRIPTION_FREQUENCY_VALUES.ONCE_DAILY
    },
    {
        abbreviated_value: 'every day',
        value: PRESCRIPTION_FREQUENCY_VALUES.EVERY_DAY
    },
    {
        abbreviated_value: 'twice daily',
        value: PRESCRIPTION_FREQUENCY_VALUES.TWICE_DAILY
    },
    {
        abbreviated_value: 'three times daily',
        value: PRESCRIPTION_FREQUENCY_VALUES.THREE_TIMES_DAILY
    },
    {
        abbreviated_value: 'four times daily',
        value: PRESCRIPTION_FREQUENCY_VALUES.FOUR_TIMES_DAILY
    },
    {
        abbreviated_value: 'five times daily',
        value: PRESCRIPTION_FREQUENCY_VALUES.FIVE_TIMES_DAILY
    },
    {
        abbreviated_value: 'every four hours',
        value: PRESCRIPTION_FREQUENCY_VALUES.EVERY_FOUR_HOURS
    },
    {
        abbreviated_value: 'every morning',
        value: PRESCRIPTION_FREQUENCY_VALUES.EVERY_MORNING
    },
    {
        abbreviated_value: 'every night',
        value: PRESCRIPTION_FREQUENCY_VALUES.EVERY_NIGHT
    },
    {
        abbreviated_value: 'once a week',
        value: PRESCRIPTION_FREQUENCY_VALUES.ONCE_WEEKLY
    },
    {
        abbreviated_value: 'twice a week',
        value: PRESCRIPTION_FREQUENCY_VALUES.TWICE_WEEKLY
    },
    {
        abbreviated_value: 'three times a week',
        value: PRESCRIPTION_FREQUENCY_VALUES.THREE_TIMES_WEEKLY
    },
    {
        abbreviated_value: 'as required',
        value: PRESCRIPTION_FREQUENCY_VALUES.AS_REQUIRED
    },
    {
        abbreviated_value: 'when required',
        value: PRESCRIPTION_FREQUENCY_VALUES.WHEN_REQUIRED
    },
    {
        abbreviated_value: 'immediately',
        value: PRESCRIPTION_FREQUENCY_VALUES.IMMEDIATELY
    },
    {
        abbreviated_value: 'before food',
        value: PRESCRIPTION_FREQUENCY_VALUES.BEFORE_FOOD
    },
    {
        abbreviated_value: 'after food',
        value: PRESCRIPTION_FREQUENCY_VALUES.AFTER_FOOD
    }
];

const TOXINS = {
    AZZALURE: 'Azzalure',
    BOTOX: 'Botox',
    DYSPORT: 'Dysport',
    XEOMIN: 'Xeomin',
    MYOBLOC: 'Myobloc'
};

const JOURNEY_FORM_TYPES = {
    PMH: 'PMH',
    CONSENT: 'Consent'
};

const JOURNEY_FORM_METHODS = {
    IN_PERSON: 'In person',
    ONLINE: 'Online'
};

/**
 * @deprecated use treatmentImages.js or getDefaultTreatmentPhotoByGender
 */
const DEFAULT_TREATMENT_PHOTO = {
    label: 'Default treatment photo',
    url: `${
        typeof localStorage !== 'undefined'
            ? // eslint-disable-next-line no-undef
              JSON.parse(localStorage.getItem('linkCache'))?.backendUrl
            : process.env.COLLUMS_API_URL
    }/images/front_face.jpg`,
    name: 'Default'
};

/**
 * @deprecated use treatmentImages.js
 */
const DEFAULT_TREATMENT_PHOTOS = [
    {
        label: 'Default treatment photo',
        url: `${
            typeof localStorage !== 'undefined'
                ? // eslint-disable-next-line no-undef
                  JSON.parse(localStorage.getItem('linkCache'))?.backendUrl
                : process.env.COLLUMS_API_URL
        }/images/full_face.jpg`,
        name: 'Full Face'
    },
    {
        label: 'Upper face for treatment photo',
        url: `${
            typeof localStorage !== 'undefined'
                ? // eslint-disable-next-line no-undef
                  JSON.parse(localStorage.getItem('linkCache'))?.backendUrl
                : process.env.COLLUMS_API_URL
        }/images/upper_face.jpg`,
        name: 'Upper Face'
    },
    {
        label: 'Lower face for treatment photo',
        url: `${
            typeof localStorage !== 'undefined'
                ? // eslint-disable-next-line no-undef
                  JSON.parse(localStorage.getItem('linkCache'))?.backendUrl
                : process.env.COLLUMS_API_URL
        }/images/lower_face.jpg`,
        name: 'Lower Face'
    }
];

const LOGO_HEIGHT = 75;

const PREFERRED_PHONE_COUNTRIES = ['gb', 'fr', 'it', 'es', 'ie'];
const DEFAULT_PHONE_COUNTRY = 'gb';
const DEFAULT_PHONE_COUNTRY_CODE = '+44';

const TC_MERGE_TAGS = ['{LateCancellationType}', '{LateCancellationValue}', '{LateCancellationPeriod}'];
const TC_MODAL_MERGE_TAGS = ['{LateCancellationPeriod}', '{LateCancellationFee}'];
const DROPDOWN_MAX_SHOWING_ITEMS = 10;

const COUNTRY_CODES = {
    'United Kingdom': 'gb',
    Australia: 'au'
};

const COUNTRY_PHONE_CODES = {
    'United Kingdom': '+44',
    Australia: '+61'
};

const LHR_HANDPIECES = {
    ET: 'ET',
    HS: 'HS'
};

const LHR_SIDES = {
    NA: 'N/a',
    Both: 'Both',
    Left: 'Left',
    Right: 'Right'
};

const LHR_COOLING = {
    Yes: 'Yes',
    No: 'No'
};

const LHR_SKIN_TYPES = Array(6)
    .fill(1)
    .map((item, index) => (item + index).toString());

const SELECTED_HP_LHR_PW = ['Auto', '30', '100', '400'];
const LHR_PWS = ['20', '21', '22'];
const WEEK_DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const TREATMENT_LIST = ['Complaint', 'General', 'Prescription', 'Test Results'];

const LHR_TREATMENT_AREAS = {
    ABDOMEN: 'Abdomen',
    ARM_FULL: 'Arm - Full',
    ARM_LOWER: 'Arm - Lower',
    ARM_UPPER: 'Arm - Upper',
    BACK: 'Back',
    BACK_LOWER: 'Back Lower',
    BACK_UPPER: 'Back Upper',
    BEARD: 'Beard',
    BIKINI: 'Bikini',
    BIKINI_BRAZILIAN: 'Bikini - Brazilian',
    BIKINI_HOLLYWOOD: 'Bikini - Hollywood',
    CHEEKS: 'Cheeks',
    CHEST: 'Chest',
    CHIN: 'Chin',
    EARS: 'Ears',
    EYEBROWS: 'Eyebrows',
    FACE_FULL: 'Face - Full',
    FACE_LOWER: 'Face - Lower',
    FACE_UPPER: 'Face - Upper',
    FINGERS: 'Fingers',
    FOOT: 'Foot',
    HAND: 'Hand',
    JAW: 'Jaw',
    LABIA: 'Labia',
    LIP_BOTTOM: 'Lip - Bottom',
    LIP_TOP: 'Lip - Top',
    LOWER_LEG: 'Lower Leg',
    NAVEL_PUBIS: 'Navel Pubis',
    NECK: 'Neck',
    NIPPLE: 'Nipple',
    NOSE: 'Nose',
    PERIANAL: 'Perianal',
    SHOULDERS: 'Shoulders',
    SIDEBURNS: 'Sideburns',
    STOMACH: 'Stomach',
    THIGH: 'Thigh',
    TOES: 'Toes',
    UNDERARMS: 'Underarms'
};

const BOTOX_TREATMENT_AREAS = {
    EYES: 'Eyes',
    FOREHEAD: 'Forehead',
    GLABELLA: 'Glabella',
    AXILLA: 'Axilla',
    BUNNY_LINES: 'Bunny lines',
    CHIN: 'Chin',
    DAO: 'DAO',
    EYEBROWS: 'Eyebrows',
    FEET: 'Feet',
    GUMMY_SMILE: 'Gummy smile',
    HANDS: 'Hands',
    NECK: 'Neck',
    PERI_ORAL: 'Peri oral',
    PLATYSMAL_BANDS: 'Platysmal bands',
    MASSETER: 'Masseter',
    UNDER_EYE: 'Under eye',
    OTHER: 'Other'
};

const VIEW_BY_OPTIONS = {
    DAY_BY_DAY: 'Day by day',
    DAILY: 'Grouped by day',
    WEEKLY: 'Grouped by week',
    MONTHLY: 'Grouped by month',
    YEARLY: 'Grouped by year'
};

const FORMS_ACTIONS_LOG = {
    CREATED: 'Form created',
    UPDATED: 'Form updated',
    NOT_CHANGED: 'Form not changed - confirmed previous data',
    SIGNED: 'Form signed'
};

const NOTE_ACTIONS_LOG = {
    CREATED: 'Created note',
    UPDATED: 'Updated note'
};

const ORGANISATION_FORMS_TARGET = {
    CLIENT: 'Client',
    PRACTITIONER: 'Practitioner'
};

const EMAIL_FORM_FOR = {
    FIRST_APPOINTMENT_ONLY: '1st appointment only',
    EVERY_APPOINTMENT: 'Every appointment'
};

const DEFAULT_UNIT_VALUES = Array(10)
    .fill(0.5)
    .map((_, index) => _ + index * 0.5);

const TREATMENT_TAG_TYPES = {
    TOXIN: 'toxin',
    FILLER: 'filler',
    LHR: 'lhr'
};

const MEDICAL_HISTORY_SELECTIONS = {
    LABEL: 'Label',
    DESCRIPTION: 'Description'
};

const PRODUCT_VOL_UNITS = {
    MLS: 'mls',
    FLOZ: 'FlOz',
    UNITS: 'Units'
};

const FILLER_AREAS = {
    BROW: 'Brow',
    BUTTOCKS: 'Buttocks',
    CHEEKS: 'Cheeks',
    CHIN: 'Chin',
    DECOLLETAGE: 'Décolletage',
    FOREHEAD: 'Forehead',
    HANDS: 'Hands',
    JAW_ANGLE_OF: 'Jaw - angle of',
    JAW_LINE: 'Jaw line',
    KNEES: 'Knees',
    LIPS: 'Lips',
    MARIONETTE: 'Marionette',
    MOUTH_CORNER: 'Mouth Corner',
    NASOLABIAL: 'Nasolabial',
    NECK: 'Neck',
    NOSE: 'Nose',
    PERI_ORAL: 'Peri oral',
    TEAR_TROUGH: 'Tear trough',
    TEMPLE: 'Temple',
    THIGHS: 'Thighs',
    UPPER_ARM: 'Upper Arm',
    ZYGOMA: 'Zygoma',
    OTHER: 'Other'
};

const COLLUMS_ACCESS_TIER = {
    STARTUP_ACCESS: 'Startup Access',
    BUILDER_ACCESS: 'Builder Access',
    EXPERT_ACCESS: 'Expert Access'
};

const COLLUMS_APPS_ACCESS_TIER = {
    CALENDAR: COLLUMS_ACCESS_TIER.STARTUP_ACCESS,
    REPORTING: COLLUMS_ACCESS_TIER.STARTUP_ACCESS,
    CUSTOMER: COLLUMS_ACCESS_TIER.STARTUP_ACCESS,
    ADMIN: COLLUMS_ACCESS_TIER.STARTUP_ACCESS,
    POS: COLLUMS_ACCESS_TIER.STARTUP_ACCESS,
    SCHEDULES: COLLUMS_ACCESS_TIER.STARTUP_ACCESS,

    JOURNEY: COLLUMS_ACCESS_TIER.BUILDER_ACCESS,

    MARKETING: COLLUMS_ACCESS_TIER.EXPERT_ACCESS,
    STOCK: COLLUMS_ACCESS_TIER.EXPERT_ACCESS
};

const JOURNEY_STEPS_ORDER = [
    'home',
    'client-details',
    'forms',
    'form-complete',
    'summary',
    'practitioner-forms',
    'photos'
];

const ACCOUNTING_TYPE = {
    SALES: 'Sales',
    REVENUE: 'Revenue'
};

const COLLUMS_DOMAIN = '.collums.co.uk';

const COOKIE_KEYS = {
    EXP_TIME: 'expTime',
    LOGOUT_ACTIVE: 'logoutActive',
    TOKEN: 'token'
};

const LOCAL_STORAGE_KEYS = {
    TOKEN_KEY: 'token',
    USER_KEY: 'user'
};

const ORGANISATION_VOUCHER_DEFAULT = 1001;

const DEPOSIT_TYPES = {
    NO_DEPOSIT: 'No deposit',
    PERCENTAGE: 'Percentage',
    FIXED_AMOUNT: 'Fixed amount'
};

const DEPOSIT_MULTISERVICE_RULE = {
    SINGLE: 'single',
    SUM: 'sum'
};

const DEPOSIT_RULE = {
    EACH_TIME: 'each time',
    ACCOUNT_DIFFERENCE: 'account difference'
};

module.exports = {
    TITLES,
    GIFTCARD,
    GENDERS,
    DEFAULT_PHOTO,
    REFERRAL_SOURCES_TYPE,
    NOTIFICATION_TYPES,
    PHONE_TYPE_OPTIONS,
    RELATIONSHIPS,
    PROFESSIONAL_BODIES,
    COMMISSION_RANGE_TYPES,
    AMOUNT_FEE_TYPE,
    ROLES,
    ORDER,
    POPUP,
    LATE_CANCELLATION_CHARGE,
    SERVICE_AVAILABILITIES,
    APPOINTMENT_TYPES,
    APPOINTMENT_REPEAT,
    APPOINTMENT_PAYMENT_TYPES,
    INVOICE_PAYMENT_TYPES,
    APPOINTMENT_STATUS_TYPES,
    INVOICE_ORIGIN,
    STOCK_TAKE,
    TAXTYPES,
    STATUS_ACTIVE,
    STATUS_DELETED,
    TEST_EMAILS,
    TEMPORARY_DISCOUNTS,
    INVOICE_PAYMENT_STATUS,
    DISCOUNT_TYPES,
    RECEIPT_STATUS,
    APPOINTMENT_FILTER_GROUPS,
    INVOICE_ITEM_TYPES,
    COURSES_GROUP,
    WIDGET_TYPES,
    ORGANISATION_FORM_TYPES,
    WIDGET_GROUPS,
    IMAGE_WIDGET_ALIGNMENT,
    IMAGE_WIDGET_SIZE,
    FORM_OPTIONS,
    PRESCRIPTION_ROUTES,
    DOSE_UNITS,
    DURATION_UNITS,
    TREATMENT_NOTE_TYPES,
    TREATMENT_TYPES,
    TREATMENT_AREAS,
    PRODUCT_TYPES,
    DILUTION_UNITS,
    PRESCRIPTION_FREQUENCY,
    PRESCRIPTION_FREQUENCY_VALUES,
    TOXINS,
    JOURNEY_FORM_TYPES,
    JOURNEY_FORM_METHODS,
    DEFAULT_TREATMENT_PHOTO,
    DEFAULT_PAYMENT_TYPES,
    CURRENCY_TYPES,
    FIXED_PAYMENT_OPTIONS,
    LOGO_HEIGHT,
    PREFERRED_PHONE_COUNTRIES,
    DEFAULT_PHONE_COUNTRY,
    DEFAULT_PHONE_COUNTRY_CODE,
    TC_MERGE_TAGS,
    TC_MODAL_MERGE_TAGS,
    DROPDOWN_MAX_SHOWING_ITEMS,
    SELECTED_HP_LHR_PW,
    LHR_PWS,
    LHR_SKIN_TYPES,
    LHR_SIDES,
    LHR_HANDPIECES,
    COMMISSION_CONDITIONS,
    WEEK_DAYS,
    TREATMENT_LIST,
    LHR_TREATMENT_AREAS,
    BOTOX_TREATMENT_AREAS,
    FORMS_ACTIONS_LOG,
    NOTE_ACTIONS_LOG,
    ORGANISATION_FORMS_TARGET,
    EMAIL_FORM_FOR,
    DEFAULT_UNIT_VALUES,
    TREATMENT_TAG_TYPES,
    MEDICAL_HISTORY_SELECTIONS,
    DEFAULT_TREATMENT_PHOTOS,
    PRODUCT_VOL_UNITS,
    FILLER_AREAS,
    COLLUMS_ACCESS_TIER,
    COLLUMS_APPS_ACCESS_TIER,
    JOURNEY_STEPS_ORDER,
    ACCOUNTING_TYPE,
    COLLUMS_DOMAIN,
    COOKIE_KEYS,
    LOCAL_STORAGE_KEYS,
    ORGANISATION_VOUCHER_DEFAULT,
    COUNTRY_CODES,
    COUNTRY_PHONE_CODES,
    LHR_COOLING,
    COHERENT_PAYMENT_PROCESS_STATUS,
    DEPOSIT_TYPES,
    DEPOSIT_MULTISERVICE_RULE,
    DEPOSIT_RULE,
    VIEW_BY_OPTIONS
};
