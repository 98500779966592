export const coherentIframeStyles = () => ({
    wrapper: {
        width: '100%'
    },
    iframe: {
        width: '100%',
        height: '700px',
        border: 0
    },
    coherentActions: {
        display: 'flex',
        justifyContent: 'right',
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        marginBottom: '25px',
        '& button': {
            textTransform: 'none',
            borderRadius: 0,
            borderColor: 'black',
            fontSize: 14,
            marginTop: '5px'
        }
    }
});
