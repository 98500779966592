import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import LoadingScreen from './../../collums-components/components/common/loadingScreen';
import { getName, getAnswersFromForm } from './../../collums-constants/utils';
import { getCustomerForm, signUserConsentPublic, updateUserFormPublic } from './../../collums-components/api/FormApi';
import { toastr } from 'react-redux-toastr';
import { Typography, withStyles, Button } from '@material-ui/core';
import { format } from 'date-fns';
import { FormViewStyles } from './styles';
import AlertDialog from './../common/Dialog/index';
import { ORGANISATION_FORM_TYPES } from '../../collums-constants';
import { FormProvider } from './../../collums-components/hooks/forms';
import Form from './../../collums-components/components/formBuilder';
import { omit } from 'lodash';
import { getCurrentTimezonedDate } from '../../collums-components/helpers/timezone';
import ConsentCanvas from '../../collums-components/components/common/ConsentCanvas';
import { getSignature } from '../../collums-components/components/common/ConsentCanvas/utils';

const FormView = ({ classes }) => {
    const { formId } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [form, setForm] = useState();
    const [redirectMessage, setRedirectMessage] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);
    const formRef = useRef();
    const canvasRef = useRef();
    const history = useHistory();
    const { search } = useLocation();
    const [password, setPassword] = useState('');

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                const urlPassword = new URLSearchParams(search).get('password');
                const data = await getCustomerForm(formId, urlPassword);
                setPassword(urlPassword);
                if (data.type === ORGANISATION_FORM_TYPES.CONSENT && data.isSigned) {
                    setRedirectMessage('Consent form already signed. Redirecting to home page');
                    return;
                }
                setForm(data);
            } catch (err) {
                setRedirectMessage('Form not found. Redirecting to home page');
            } finally {
                setIsLoading(false);
            }
        })();
    }, [formId, search]);

    const saveConsent = async () => {
        if (isDisabled) return;

        const signature = getSignature(canvasRef);
        try {
            setIsLoading(true);
            await signUserConsentPublic(formId, {
                signature,
                password
            });
            setRedirectMessage('Thank you for completing your form.');
        } catch (err) {
            toastr.error(err?.data?.message || 'Something went wrong');
        } finally {
            setIsLoading(false);
        }
    };

    const saveForm = async () => {
        if (isDisabled) return;
        try {
            const { fields } = formRef.current;

            const { answers } = getAnswersFromForm(fields);

            setIsLoading(true);
            const data = {};
            answers.forEach(el => {
                data[el.id] = omit(el, ['id']);
            });
            await updateUserFormPublic(formId, {
                data,
                password
            });
            setRedirectMessage('Thank you for completing your form.');
        } catch (err) {
            toastr.error(err?.data?.message || 'Something went wrong');
        } finally {
            setIsLoading(false);
        }
    };

    const redirectHome = () => {
        history.push('/');
    };

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
            document.getElementById('scroll-to').scrollIntoView();
        }, 500);
    }, []);

    return (
        <>
            <div id="scroll-to"></div>
            <div className="content-width" />
            {isLoading && <LoadingScreen />}
            {redirectMessage && (
                <AlertDialog
                    content={redirectMessage}
                    isVisible
                    setVisibility={redirectHome}
                    actionOnContinue={redirectHome}
                    customActionName="Close"
                />
            )}
            {form?.type === ORGANISATION_FORM_TYPES.CONSENT && (
                <div className={classes.consentContainer}>
                    <div className={classes.consent}>
                        <div className="consent" dangerouslySetInnerHTML={{ __html: form.model?.consent }} />
                    </div>
                    <Typography>{format(getCurrentTimezonedDate(), 'dd/MM/Y')}</Typography>
                    <Typography>
                        {getName(form.customer)}, dob{' '}
                        {form.customer.dateOfBirth &&
                            format(getCurrentTimezonedDate(form.customer.dateOfBirth), 'dd/MM/yyyy')}
                    </Typography>
                    <ConsentCanvas
                        isSigned={!isDisabled}
                        canvasRef={canvasRef}
                        setIsSigned={isSigned => setIsDisabled(!isSigned)}
                    />
                </div>
            )}
            {[
                ORGANISATION_FORM_TYPES.GENERAL,
                ORGANISATION_FORM_TYPES.MEDICAL,
                ORGANISATION_FORM_TYPES.AFTERCARE
            ].includes(form?.type) && (
                <div className={classes.formContentWrapper}>
                    <FormProvider
                        value={{
                            defaultData: formRef.current || form.model,
                            isJourney: true,
                            onDataChange: data => {
                                formRef.current = data;
                            },
                            onErrorChange: isInvalidForm => {
                                setIsDisabled(isInvalidForm);
                            },
                            isReadOnly: !!form?.isUpdated,
                            signatureAlwaysEmpty: !form?.isUpdated
                        }}
                    >
                        <Form />
                    </FormProvider>
                </div>
            )}
            {form && (
                <div
                    className={
                        form?.type === ORGANISATION_FORM_TYPES.CONSENT
                            ? classes.consentButtonContainer
                            : classes.formButtonContainer
                    }
                >
                    <Button
                        onClick={() => {
                            if (form.type === ORGANISATION_FORM_TYPES.CONSENT) {
                                saveConsent();
                                return;
                            }
                            saveForm();
                        }}
                        className={classes.saveUserForm}
                        variant="contained"
                        color="primary"
                        disabled={isDisabled || form?.isUpdated}
                    >
                        Save
                    </Button>
                </div>
            )}
        </>
    );
};

export default withStyles(FormViewStyles)(FormView);
