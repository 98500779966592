import api from './api';

class OrganisationApi {
    static getPublicOrganisation() {
        return api.request({
            method: 'GET',
            url: '/public/organisations'
        });
    }

    static checkCardRequirement() {
        return api.request({
            method: 'GET',
            url: '/public/organisations/card-requirement'
        });
    }
}

export default OrganisationApi;
