import React from 'react';
import { useDispatch } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';

import { setInitialState } from '../../redux/actions/book-appointment';

import { MOBILE } from '../../constants/breakpoints';

const useStyles = makeStyles(theme => ({
    root: {
        margin: '.4rem 1.6rem .4rem auto',
        float: 'right',

        [theme.breakpoints.up(MOBILE)]: {
            float: 'initial'
        }
    }
}));

function BookAppointmentButton() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const isMobile = useMediaQuery(`(max-width:${MOBILE}px)`);

    return (
        <Button
            onClick={() => {
                dispatch(setInitialState());
                history.push('/?newAppt=true');
            }}
            className={classes.root}
            variant="contained"
            color="primary"
            style={isMobile ? { marginRight: 0 } : {}}
        >
            {isMobile ? 'Book' : 'Book new appointment'}
        </Button>
    );
}

export default BookAppointmentButton;
