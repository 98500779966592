import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { differenceInHours, format, isPast } from 'date-fns';
import LoadingScreen from '../../../collums-components/components/common/loadingScreen';
import { APPOINTMENT_STATUS_TYPES } from '../../../collums-constants/index';
import { withStyles } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { MOBILE } from '../../../constants/breakpoints';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import {
    auth as authSelector,
    isAuthenticated as isAuthenticatedSelector,
    appointments,
    isFetchingAppointments
} from '../../../redux/selectors/auth';
import { getAppointment } from '../../../redux/actions/auth';
import Actions from './Actions';

import { appointmentTable } from './styles';
import { getCurrentTimezonedDate } from '../../../collums-components/helpers/timezone';

const Appointment = ({ classes }) => {
    const dispatch = useDispatch();

    const auth = useSelector(authSelector);
    const data = useSelector(appointments);
    const isAuthenticated = useSelector(isAuthenticatedSelector);
    const isFetching = useSelector(isFetchingAppointments);
    const cancellationRules = useSelector(state => state.auth.organisation?.cancellationRules || 'allow');
    const cancellationPeriod = useSelector(state => state.auth.organisation?.lateCancellationPeriod || 48);
    const isMobile = useMediaQuery(`(max-width:${MOBILE}px)`);

    useEffect(() => {
        if (!isAuthenticated) return;
        dispatch(getAppointment(auth.user.id));
        /*eslint-disable-next-line */
    }, [auth.user.id]);

    const hoursToAppointment = appointment =>
        differenceInHours(getCurrentTimezonedDate(appointment.event.start), getCurrentTimezonedDate());

    const renderContent = () => {
        return (
            (data || [])
                .filter(appt => appt.status !== APPOINTMENT_STATUS_TYPES.DELETED)
                /*eslint-disable-next-line */
                .map((appt, index) => {
                    /*eslint-disable-next-line */
                    if (appt.invoice?.origin === 'CANCELLATION') return;
                    let condition = true;
                    if (!isPast(getCurrentTimezonedDate(appt.event.start)) && cancellationRules !== 'do-not-allow') {
                        condition = appt.checkedIn || appt.status !== APPOINTMENT_STATUS_TYPES.SCHEDULED;
                        if (
                            !condition &&
                            cancellationRules === 'allow' &&
                            hoursToAppointment(appt) < cancellationPeriod
                        ) {
                            condition = true;
                        }
                    }
                    return (
                        <TableRow
                            key={appt.id}
                            className={classes.row}
                            style={{
                                backgroundColor: index % 2 === 0 ? '#efefef' : '#fff'
                            }}
                        >
                            <TableCell component="th" scope="row">
                                {format(getCurrentTimezonedDate(appt.event.start), 'dd/MM/yyyy HH:mm')}
                            </TableCell>
                            <TableCell>{appt.service.name}</TableCell>
                            <TableCell>{appt.practitioner.displayName}</TableCell>
                            <TableCell>{appt.clinic.accountName || ''}</TableCell>
                            <TableCell>
                                <Actions condition={condition} id={appt.id} />
                            </TableCell>
                        </TableRow>
                    );
                })
        );
    };

    return (
        <>
            {isFetching && <LoadingScreen />}
            {isMobile && <div className={classes.popper}> Swipe table to see options </div>}
            <TableContainer component={Paper} className={classes.container} id="scroll-bottom">
                <Table className={classes.table} size="small" aria-label="booked appointments">
                    <TableHead>
                        <TableRow id="table">
                            <TableCell>Date</TableCell>
                            <TableCell>Service</TableCell>
                            <TableCell>Staff</TableCell>
                            <TableCell>Location</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    {!!data.length && <TableBody>{renderContent()}</TableBody>}
                </Table>
                {!data.length && (
                    <div className={classes.noContent}>
                        <p>No content to show.</p>
                    </div>
                )}
            </TableContainer>
        </>
    );
};

export default withStyles(appointmentTable)(Appointment);
