import React, { useMemo } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import StripeCreditCardConfirmationInner from './StripeCreditCardConfirmationInner';

const StripeCreditCardConfirmation = props => {
    const stripePromise = useMemo(() => {
        return loadStripe(process.env.REACT_APP_STRIPE_PUSHABLE_KEY);
    }, []);

    return (
        <Elements stripe={stripePromise}>
            <StripeCreditCardConfirmationInner {...props} />
        </Elements>
    );
};

export default StripeCreditCardConfirmation;
