import { MOBILE } from '../../constants/breakpoints';

export const stepStyles = theme => ({
    title: {
        fontSize: 30
    },
    stepper: {
        '& .MuiStep-root': {
            cursor: 'pointer'
        },
        [theme.breakpoints.down(MOBILE)]: {
            backgroundColor: theme.palette.gray.light,
            padding: '16px 0'
        }
    },
    boldSubtitle: {
        marginTop: 8,
        fontWeight: 'bold'
    },
    stepContainer: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up(MOBILE)]: {
            marginTop: '.8rem'
        }
    },
    stepContent: {
        display: 'flex',
        flexDirection: 'column-reverse',
        width: '100%',
        [theme.breakpoints.up(MOBILE)]: {
            flexDirection: 'row'
        }
    },
    covidMessage: {
        marginTop: 8,
        marginBottom: 8,
        borderColor: '#f7d089',
        borderRadius: 10,
        backgroundColor: '#ffe36b96',
        borderWidth: '1px',
        borderStyle: 'solid',
        '& p': {
            margin: 8
        },
        '& .MuiTypography-body1': {
            color: '#373737',
            fontSize: '14px !important'
        },
        '& a': {
            color: 'blue',
            textDecoration: 'underline'
        }
    },
    previewContent: {
        display: 'flex',
        marginTop: 16,
        height: '100%',

        [theme.breakpoints.up(MOBILE)]: {
            margin: '1 .8rem 0 .8rem',
            width: '80%'
        }
    },
    detailContainer: {
        display: 'flex',
        width: '100%',
        borderRadius: 10,
        flexDirection: 'column',

        [theme.breakpoints.up(MOBILE)]: {
            width: '70%',
            border: '1px solid #ccc'
        }
    },
    detailItem: {
        display: 'flex',
        flexDirection: 'column',
        padding: 16,
        paddingTop: 8,
        paddingBottom: 8,

        '& span': {
            color: '#000000DE'
        }
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',

        '& a': {
            color: theme.palette.primary.main,
            cursor: 'pointer',
            '&:hover': {
                textDecoration: 'underline'
            }
        }
    },
    stickyNav: {
        position: 'sticky',
        top: 0,
        zIndex: 10,

        [theme.breakpoints.up(MOBILE)]: {
            position: 'initial'
        }
    },
    countdownMessage: {
        textAlign: 'center',
        position: 'sticky',
        top: 0,
        backgroundColor: '#FFF',

        '& span': {
            fontWeight: 'bold',
            padding: '.4rem'
        }
    },
    right: {
        float: 'right'
    },
    aBtn: {
        color: '#3083EC',
        backgroundColor: 'transparent',
        fontWeight: 'bold',
        padding: '1rem 0',
        '&:hover': {
            backgroundColor: 'transparent',
            color: '#215ba5'
        }
    },
    welcome: {
        margin: '0.5rem 0',
        color: 'grey',
        fontWeight: '500',
        fontSize: '0.85rem'
    },
    backButton: {
        display: 'flex',
        height: 'fit-content',
        alignItems: 'center',
        padding: '0 1rem',
        backgroundColor: 'transparent',
        width: 'fit-content',
        justifyContent: 'flex-start',
        color: theme.palette.primary.main,
        '&:hover': {
            cursor: 'pointer'
        },
        [theme.breakpoints.up(MOBILE)]: {
            padding: '1rem'
        }
    },
    serviceSubtitle: {
        padding: '1rem 0 0 1rem'
    },
    widthFit: {
        width: 'fit-content'
    },
    headerWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down(MOBILE)]: {
            padding: '1rem 0'
        }
    },
    deleteBtn: {
        display: 'inline-flex',
        fontSize: '18px',
        alignSelf: 'center',
        position: 'relative',
        top: '.125em'
    }
});

export const removeServiceDialogStyles = theme => ({
    button: {
        textTransform: 'none',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        '&:hover': {
            backgroundColor: theme.palette.primary.light
        },
        margin: '.4rem'
    },
    confirm: {
        textTransform: 'none',
        backgroundColor: 'red',
        color: 'white',
        margin: '.4rem'
    },
    text: {
        fontSize: '1rem',
        color: theme.palette.black.main,
        textAlign: 'center'
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: '50vh'
    },
    mobileActions: {
        textAlign: 'center'
    }
});
