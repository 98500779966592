export const toSeconds = value => {
    const sec_num = parseInt(value, 10); // don't forget the second param
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - hours * 3600) / 60);
    let seconds = sec_num - hours * 3600 - minutes * 60;
    if (hours < 10) {
        hours = '0' + hours;
    }
    if (minutes < 10) {
        minutes = '0' + minutes;
    }
    if (seconds < 10) {
        seconds = '0' + seconds;
    }
    return minutes + ':' + seconds;
};

export const passwordValidator = /(^(?=.*\d)(?=.*[A-Z]).{8,}$)/;

export const getAddress = clinic => {
    const city = clinic.address.city;
    const line1 = clinic.address.line1;
    const line2 = clinic.address.line2;
    if (line1 || line2) {
        return `${line1 ? `${line1} ` : ''}${line2 || ''}, ${city}`;
    }
    return city;
};
