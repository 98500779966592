import React, { useState, useEffect } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { Box, Button, Typography, withStyles } from '@material-ui/core';
import { confirmationStyles } from './styles';

const StripeCreditCardConfirmationInner = ({ classes, clientSecret, handleNext, closeConfirmation }) => {
    const stripe = useStripe();
    const [message, setMessage] = useState('');
    const [showButton, setShowButton] = useState(true);

    useEffect(() => {
        if (!stripe) {
            return;
        }
        if (clientSecret) {
            retrieveSetupIntent();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stripe, clientSecret]);

    const retrieveSetupIntent = () => {
        stripe.retrieveSetupIntent(clientSecret).then(data => {
            switch (data && data.setupIntent.status) {
                case 'succeeded':
                    setMessage('Success! Your payment method has been saved.');
                    setShowButton(false);
                    handleNext({ selectedCard: 'NEW_CARD' }, () => {});
                    break;

                case 'processing':
                    setTimeout(() => {
                        retrieveSetupIntent();
                    }, 1000);
                    setMessage("Processing payment details. We'll update you when processing is complete.");
                    setShowButton(false);
                    break;

                case 'requires_payment_method':
                    setMessage('Failed to process payment details. Please try another payment method.');
                    setShowButton(true);
                    break;

                default:
                    break;
            }
        });
    };

    return (
        <Box className={classes.wrapper}>
            <Typography variant="h5">Processing ....</Typography>
            <Typography variant="h4">{message}</Typography>
            {showButton && (
                <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={() => closeConfirmation()}
                >
                    Change payment method
                </Button>
            )}
        </Box>
    );
};

export default withStyles(confirmationStyles)(StripeCreditCardConfirmationInner);
