import { MOBILE } from '../../../../constants/breakpoints';

export const addCardStyles = theme => ({
    formWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    cardsDropdown: {
        margin: '0 auto',
        width: '50%',

        '& .MuiFormControl-root': {
            width: '100%'
        }
    },
    form: {
        width: '100%',
        padding: '20px'
    },
    cardHeader: {
        display: 'flex',
        width: '100%',
        justifyContent: ' center',
        marginBottom: '3vh'
    },
    cardTitle: {
        fontSize: '1.23rem'
    },
    cardRoot: {
        width: 'fit-content',
        margin: 'auto',
        marginBottom: 16,
        maxWidth: 680,
        minWidth: 380,
        [theme.breakpoints.down(MOBILE)]: {
            margin: '0 0 16px 0',
            width: '100%'
        }
    },
    cardSelector: {
        width: '100%'
    },
    cardInfoRoot: {
        [theme.breakpoints.down(MOBILE)]: {
            '& > div': {
                margin: 0,
                width: '100%'
            }
        },
        [theme.breakpoints.up(MOBILE)]: {
            '& > div': {
                margin: 0,
                width: '40%'
            }
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    cardSelectorRoot: {
        height: 'calc(100% + 8px)',
        boxSizing: 'border-box',
        fontSize: '14px'
    },
    bookAppointmentButton: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        marginTop: '5vh'
    },
    loadingTitle: {
        '& h2': {
            fontSize: '18px',
            fontWeight: '500',
            textAlign: 'center',
            fontFamily: "Roboto, Arial, 'sans-serif'"
        }
    },
    loadingRoot: {
        '& .MuiPaper-root': {
            width: '50%'
        }
    }
});

export const confirmationStyles = () => ({
    wrapper: {
        padding: '20px',

        '& > h5': {
            fontSize: '1rem',
            color: '#5c5c5c'
        },
        '& > h4': {
            marginTop: '15px'
        }
    },
    button: {
        marginTop: '20px'
    }
});

export const stripeCreditCardFormStyles = theme => ({
    formWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    cardsDropdown: {
        margin: '0 auto',
        width: '50%',

        '& .MuiFormControl-root': {
            width: '100%'
        }
    },
    form: {
        width: '100%',
        padding: '20px'
    },
    cardHeader: {
        display: 'flex',
        width: '100%',
        justifyContent: ' center',
        marginBottom: '3vh'
    },
    cardTitle: {
        fontSize: '1.23rem'
    },
    cardRoot: {
        width: 'fit-content',
        margin: 'auto',
        marginBottom: 16,
        maxWidth: 680,
        minWidth: 380,
        [theme.breakpoints.down(MOBILE)]: {
            margin: '0 0 16px 0',
            width: '100%'
        }
    },
    cardSelector: {
        width: '100%'
    },
    cardInfoRoot: {
        [theme.breakpoints.down(MOBILE)]: {
            '& > div': {
                margin: 0,
                width: '100%'
            }
        },
        [theme.breakpoints.up(MOBILE)]: {
            '& > div': {
                margin: 0,
                width: '40%'
            }
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    cardSelectorRoot: {
        height: 'calc(100% + 8px)',
        boxSizing: 'border-box',
        fontSize: '14px'
    },
    bookAppointmentButton: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        marginTop: '5vh'
    },
    information: {
        textAlign: 'center',
        fontWeight: 500
    },
    button: {
        border: 0,
        cursor: 'pointer',
        display: 'inline-flex',
        outline: 0,
        alignItems: 'center',
        verticalAlign: 'middle',
        justifyContent: 'center',
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: '10px',
        minWidth: '64px',
        boxSizing: 'border-box',
        transition:
            'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        fontFamily: 'Gilmer Regular, Roboto',
        fontWeight: 500,
        lineHeight: 1.75,
        textTransform: 'uppercase',
        height: '44px',
        margin: '20px 10px 10px 0px',
        padding: '6px 14px',
        backgroundColor: '#b41778',
        '&:disabled': {
            cursor: 'default',
            backgroundColor: '#ccc'
        }
    },
    buttonSpan: {
        cursor: 'pointer',
        boxSizing: 'inherit',
        margin: 0,
        fontWeight: 400,
        lineHeight: 1.5,
        color: '#ffffff',
        fontSize: '14px',
        fontFamily: 'Gilmer Medium',
        textTransform: 'none'
    },
    warning: {
        textAlign: 'center',
        padding: '10px',
        fontSize: '16px',
        fontWeight: 500,
        color: '#fff',
        marginTop: '10px',
        background: '#cf2a27'
    }
});

export const stripeCardStyles = () => ({
    title: {
        '& h2': {
            fontSize: '18px',
            fontWeight: '500',
            textAlign: 'center',
            fontFamily: "Roboto, Arial, 'sans-serif'"
        }
    },
    root: {
        '& .MuiPaper-root': {
            width: '50%'
        }
    },
    content: {
        width: '100%'
    },
    redirectMessage: {
        width: '100%',
        fontSize: '20px',
        color: '#584d4d',
        textAlign: 'center',
        marginBottom: '15px'
    },
    button: {
        border: 0,
        cursor: 'pointer',
        display: 'inline-flex',
        outline: 0,
        alignItems: 'center',
        verticalAlign: 'middle',
        justifyContent: 'center',
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: '10px',
        minWidth: '64px',
        boxSizing: 'border-box',
        transition:
            'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        fontFamily: 'Gilmer Regular, Roboto',
        fontWeight: 500,
        lineHeight: 1.75,
        textTransform: 'uppercase',
        height: '44px',
        margin: '20px 10px 10px 0px',
        padding: '6px 14px',
        backgroundColor: '#b41778'
    },
    buttonSpan: {
        cursor: 'pointer',
        boxSizing: 'inherit',
        margin: 0,
        fontWeight: 400,
        lineHeight: 1.5,
        color: '#ffffff',
        fontSize: '14px',
        fontFamily: 'Gilmer Medium',
        textTransform: 'none'
    }
});
