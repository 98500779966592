import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles, CircularProgress, Divider, Typography, IconButton } from '@material-ui/core';
import { Info } from '@material-ui/icons';

import Selector from '../../common/Selector';
import InfoModal from './InfoModal';

import ServicesApi from '../../../api/servicesApi';
import { STAFF } from '../../../constants/steps';
import {
    getCurrentAppointments,
    getCurrentCategory,
    getCurrentLocation
} from '../../../redux/selectors/book-appointment';
import {
    changeService,
    changeStep,
    setCurrentCategory,
    updateAppointments
} from '../../../redux/actions/book-appointment';
import { toLocaleString } from '../../../collums-components/helpers';

import { serviceStyles } from './styles';
import { SERVICE_AVAILABILITIES } from '../../../collums-constants';
import { getLocationItem } from '../../../collums-constants/utils';
// import { DURATION_IO_FROM_FRONT } from '../../../constants/socket';

function Service({ classes, serviceIndex }) {
    // const [savedCategories, setSavedCategories] = useState([]);
    const savedHaveMore = useRef(false);
    const haveMore = useRef(true);
    const dispatch = useDispatch();
    const makingRequest = useRef(false);
    const [skipServices, setSkipServices] = useState(0);
    const [skipCategories, setSkipCategories] = useState(0);
    const currentAppts = useSelector(getCurrentAppointments);
    const currentLocation = useSelector(getCurrentLocation);
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [modalProps, setModalProps] = useState({
        title: '',
        content: '',
        isVisible: false
    });
    const [isLoadingMore, setLoadingMore] = useState(false);
    const history = useHistory();
    const { url } = useRouteMatch();

    const currentCategory = useSelector(getCurrentCategory);

    const openModal = data => {
        setModalProps({
            isVisible: true,
            title: data.title,
            content: data.content
        });
    };

    const handleCurrentOption = opt => {
        const urlParts = url.split('/');
        if (!opt) {
            dispatch(setCurrentCategory(null));
        } else if (!currentCategory) {
            dispatch(setCurrentCategory(opt));
            history.push(`${url}/${opt.id}?service=${serviceIndex}`);
        } else {
            // socket.send({
            //     type: DURATION_IO_FROM_FRONT,
            //     payload: opt.defaultDuration
            // });

            if (!opt.availability && urlParts.length === 4) {
                urlParts[3] = opt.id;
                dispatch(setCurrentCategory(opt));
                setSkipServices(0);
                history.push(urlParts.join('/') + '?service=' + serviceIndex);
            } else {
                const tempCurrAppts = [...currentAppts];
                if (currentAppts.length - 1 < Number(serviceIndex)) {
                    tempCurrAppts.push({
                        service: opt,
                        staff: null
                    });
                } else {
                    tempCurrAppts[Number(serviceIndex)] = {
                        service: opt,
                        staff: null
                    };
                }

                dispatch(updateAppointments(tempCurrAppts));
                dispatch(changeService(opt));
                dispatch(changeStep(STAFF));
                history.push(`${url}/${opt.id}?service=${serviceIndex}`);
            }
        }
    };

    async function fillCategories(forceSkipTo0 = false) {
        if (!currentLocation?.id) return;
        let skipCategoriesNumber = skipCategories;
        if (forceSkipTo0) {
            skipCategoriesNumber = 0;
        }
        const currentSkipCategories = skipCategoriesNumber;
        const maxSize = 1500;
        if (skipCategoriesNumber === 0) {
            setIsLoading(true);
        } else {
            setLoadingMore(true);
        }
        try {
            const res = (await ServicesApi.getCategories(currentLocation.id, skipCategoriesNumber, maxSize)).filter(
                cat => !cat.parentCategory
            );
            if (skipCategoriesNumber === 0) {
                setOptions(res);
                setIsLoading(false);
            } else {
                const filteredCategories = res.filter(category => {
                    return options.find(element => element.id === category.id) === undefined;
                });
                if (filteredCategories.length > 0) {
                    const newOptions = [...options, ...filteredCategories];
                    setOptions(newOptions);
                }
                setLoadingMore(false);
            }
            setSkipCategories(res.length + skipCategoriesNumber);
            if (res.length < maxSize) {
                haveMore.current = false;
            } else haveMore.current = true;
            makingRequest.current = false;

            if (currentSkipCategories === 0) {
                // scrollToSteps();
            }
        } catch (err) {
            haveMore.current = false;
            setLoadingMore(false);
            setIsLoading(false);
        }
    }

    const scrollToSteps = () => {
        const stepElement = document.querySelector('.MuiStepper-root');
        if (stepElement) {
            setTimeout(() => {
                stepElement.scrollIntoView({ behavior: 'instant', block: 'start' });
            }, 100);
        }
    };

    async function fillServices() {
        const maxSize = 15;
        const currentSkipServices = skipServices;
        if (skipServices === 0) {
            setIsLoading(true);
        } else {
            setLoadingMore(true);
        }
        try {
            const res = await ServicesApi.getServices(currentLocation.id, currentCategory.id, skipServices, maxSize);
            const filteredServices = res.filter(
                service => service.onlineName !== undefined && service.availability !== SERVICE_AVAILABILITIES.BOOK_ONLY
            );
            const servicesWithSubcategories = [
                ...currentCategory.subCategories
                    .filter(cat => typeof cat !== 'string')
                    .filter(cat => {
                        return cat.locations.find(loc => loc.clinic === currentLocation.id)?.showOnline;
                    }),
                ...filteredServices
            ];
            if (skipServices === 0) {
                if (![...currentCategory.subCategories].find(cat => typeof cat === 'string')) {
                    setOptions(servicesWithSubcategories);
                    setIsLoading(false);
                }
            } else {
                const newOptions = [...options, ...filteredServices];
                setOptions(newOptions);
            }

            setLoadingMore(false);
            setSkipServices(res.length + skipServices);
            if (res.length < maxSize) {
                haveMore.current = false;
            } else haveMore.current = true;
            makingRequest.current = false;
            if (currentSkipServices === 0) {
                scrollToSteps();
            }
        } catch (err) {
            haveMore.current = false;
            setLoadingMore(false);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (currentCategory) {
            // if (!currentCategory.parentCategory) {
            //     setSavedCategories(options);
            // }
            savedHaveMore.current = haveMore;
            fillServices();
        } else {
            setSkipServices(0);
            setSkipCategories(0);
            // if (savedCategories.length > 0) {
            //     setOptions(savedCategories);
            //     haveMore.current = savedHaveMore;
            // } else {
            fillCategories(true);
            // }
        }
        /*eslint-disable-next-line */
    }, [currentCategory, currentLocation.id]);

    const handleInfoClick = (event, option) => {
        event.stopPropagation();
        openModal({
            isVisible: true,
            content: getLocationItem(option, currentLocation.id)?.onlineDescription,
            title: getLocationItem(option, currentLocation.id)?.onlineName || option.onlineName || option.name
        });
    };

    const handleScroll = async event => {
        const element = event.target;
        if (makingRequest.current) return;
        const scrollPosition = element.scrollTop;
        if (
            element.scrollTop / (element.scrollHeight - element.clientHeight) >= 1 &&
            haveMore.current &&
            !currentCategory
        ) {
            makingRequest.current = true;
            fillCategories();
            element.scrollTop = scrollPosition;
        }
        if (
            Math.ceil(element.scrollTop) / (element.scrollHeight - element.clientHeight) >= 1 &&
            haveMore.current &&
            currentCategory
        ) {
            makingRequest.current = true;
            fillServices();
            element.scrollTop = scrollPosition;
        }
    };

    const printDuration = loc => {
        const durations = loc.staffs.map(staff => {
            if (staff.duration) {
                return staff.duration;
            }
            return loc?.defaultDuration;
        });
        const minDuration = Math.min(...durations);
        const maxDuration = Math.max(...durations);
        return (minDuration !== maxDuration ? 'From ' : '') + minDuration;
    };

    return (
        <div className={classes.servicesRoot} onScroll={handleScroll}>
            {isLoading ? (
                <CircularProgress />
            ) : (
                <>
                    <Selector className="overflowVisible" id="Scrollable">
                        {options && options.length > 0 ? (
                            options.map(option => {
                                return (
                                    <React.Fragment key={option.id}>
                                        <Selector.Row onClick={() => handleCurrentOption(option)}>
                                            <Selector.Column>
                                                <div style={{ display: 'flow-root' }}>
                                                    <Typography className={classes.name} variant="h4">
                                                        {getLocationItem(option, currentLocation.id)?.onlineName ||
                                                            option.name}
                                                    </Typography>
                                                    {getLocationItem(option, currentLocation.id)?.onlineDescription && (
                                                        <IconButton
                                                            onClick={event => handleInfoClick(event, option)}
                                                            className={classes.info}
                                                        >
                                                            <Info className={classes.infoIcon} />
                                                        </IconButton>
                                                    )}
                                                    {option.showPriceOnline && option.metadata && (
                                                        <div style={{ float: 'right' }}>
                                                            <Typography className={classes.pricing} variant="h4">
                                                                {option.metadata.lowestPrice >= 0 &&
                                                                option.metadata.highestPrice >= 0 &&
                                                                option.metadata.lowestPrice !==
                                                                    option.metadata.highestPrice
                                                                    ? 'From '
                                                                    : ''}{' '}
                                                                {toLocaleString(option.metadata.price)}
                                                            </Typography>
                                                        </div>
                                                    )}
                                                    {option.showPriceOnline &&
                                                        option.defaultPrice !== undefined &&
                                                        !option.metadata && (
                                                            <Typography className={classes.pricing} variant="h4">
                                                                {`From ${toLocaleString(option.defaultPrice)}`}
                                                            </Typography>
                                                        )}
                                                </div>
                                                {getLocationItem(option, currentLocation.id)?.defaultDuration &&
                                                    option.showDurationOnline && (
                                                        <Typography variant="body2" style={{ margin: '0.35rem 0 0' }}>
                                                            {printDuration(getLocationItem(option, currentLocation.id))}
                                                            mins
                                                        </Typography>
                                                    )}
                                                <Typography variant="body2">
                                                    {getLocationItem(option, currentLocation.id)?.onlineSubtitle || ''}
                                                </Typography>
                                            </Selector.Column>
                                        </Selector.Row>
                                        <Divider />
                                    </React.Fragment>
                                );
                            })
                        ) : (
                            <Typography style={{ padding: 10, textAlign: 'center' }}>
                                {currentCategory
                                    ? 'There are no services currently available'
                                    : 'Online booking is currently unavailable'}
                            </Typography>
                        )}
                        {isLoadingMore && <CircularProgress />}
                    </Selector>
                    <InfoModal
                        setIsVisible={setModalProps}
                        isVisible={modalProps.isVisible}
                        title={modalProps.title}
                        content={modalProps.content}
                    />
                </>
            )}
        </div>
    );
}

Service.propTypes = {
    classes: PropTypes.object.isRequired,
    serviceIndex: PropTypes.number
};

export default withStyles(serviceStyles)(Service);
